import React from "react";
import { useAuth, useCart } from "@saleor/sdk";
import Media from "react-media";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import { OverlayContext } from "..";
import { RenderMainMenuLeft } from "./MainMenuLeft";
import { RenderMainMenuRight } from "./MainMenuRight";
import { RenderLinkMenu } from "./LinkMenu";

var MainMenu = function MainMenu() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCart = useCart(),
      items = _useCart.items;

  var _useAuth2 = useAuth(),
      signOut = _useAuth2.signOut;

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement(React.Fragment, null, React.createElement("nav", {
      className: "main-menu",
      id: "header"
    }, React.createElement("div", {
      className: "main-menu__left"
    }, React.createElement(RenderMainMenuLeft, {
      overlayContext: overlayContext,
      user: user
    })), React.createElement("div", {
      className: "main-menu__right"
    }, React.createElement(RenderMainMenuRight, {
      overlayContext: overlayContext,
      user: user,
      items: items,
      handleSignOut: handleSignOut
    }))), React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("nav", {
          className: "link-menu",
          id: "header"
        }, React.createElement("div", {
          className: "link-menu__right"
        }, user && React.createElement(RenderLinkMenu, {
          overlayContext: overlayContext,
          user: user,
          handleSignOut: handleSignOut
        })));
      }
    }));
  });
};

export default MainMenu;