import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AccountMenu = function AccountMenu(_ref) {
  var links = _ref.links,
      active = _ref.active;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.MenuHeader, null, React.createElement(FormattedMessage, commonMessages.myAccount)), links.map(function (link) {
    var menuItem = link.replace(/\//g, "").replace("-", " ").split(" ").map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1);
    }).join(" ");
    var menuItemTrans = menuItem;
    /* eslint-disable default-case */

    switch (menuItem) {
      case "Account":
        menuItemTrans = intl.formatMessage(commonMessages.account);
        break;

      case "My Orders":
        menuItemTrans = intl.formatMessage(commonMessages.orderHistory);
        break;

      case "My Offers":
        menuItemTrans = "Offers";
        break;

      case "Address Book":
        menuItemTrans = intl.formatMessage(commonMessages.addressBook);
        break;
    }

    return React.createElement(Link, {
      to: link,
      key: link,
      "data-test": "accountMenuLink",
      "data-test-id": link
    }, React.createElement(S.MenuItem, {
      active: active === link
    }, menuItemTrans));
  }));
};