import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  query ElectricServiceObject($electricSlug: String) {\n    collection(slug: $electricSlug) {\n      id\n      slug\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query MachineDetailSpareParts(\n    $machineId: ID\n    $pageSize: Int\n    $after: String\n    $search: String\n  ) {\n    collection(id: $machineId) {\n      id\n      slug\n      name\n      relationId\n      backgroundImage {\n        url\n      }\n      isPublished\n      publicationDate\n      metadata {\n        key\n        value\n      }\n      collectionProducts(\n        first: $pageSize\n        after: $after\n        filter: { sparePart: true, search: $search }\n      ) {\n        totalCount\n        edges {\n          node {\n            quantity\n            length\n            sparePart\n            product {\n              id\n              name\n              isPublished\n              slug\n              variants {\n                id\n                stocks {\n                  quantity\n                }\n                price {\n                  currency\n                  amount\n                }\n              }\n              thumbnail {\n                url\n                alt\n              }\n              thumbnail2x: thumbnail(size: 510) {\n                url\n              }\n              category {\n                id\n                name\n              }\n              metadata {\n                key\n                value\n              }\n            }\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query MachineDetail(\n    $machineId: ID\n    $pageSize: Int\n    $after: String\n    $search: String\n  ) {\n    collection(id: $machineId) {\n      id\n      slug\n      name\n      description\n      relationId\n      backgroundImage {\n        url\n      }\n      isPublished\n      publicationDate\n      metadata {\n        key\n        value\n      }\n      collectionProducts(\n        first: $pageSize\n        after: $after\n        filter: { search: $search }\n      ) {\n        totalCount\n        edges {\n          node {\n            quantity\n            length\n            sparePart\n            product {\n              id\n              name\n              isPublished\n              slug\n              variants {\n                id\n                stocks {\n                  quantity\n                }\n                price {\n                  currency\n                  amount\n                }\n              }\n              thumbnail {\n                url\n                alt\n              }\n              thumbnail2x: thumbnail(size: 510) {\n                url\n              }\n              category {\n                id\n                name\n              }\n              metadata {\n                key\n                value\n              }\n            }\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var machineDetailQuery = gql(_templateObject());
export var machineDetailSparePartsQuery = gql(_templateObject2());
export var electricalServiceObjectQuery = gql(_templateObject3());
export var TypedMachineDetailQuery = TypedQuery(machineDetailQuery);
export var TypedMachineDetailSparePartsQuery = TypedQuery(machineDetailSparePartsQuery);
export var TypedElectricalServiceObjectQuery = TypedQuery(electricalServiceObjectQuery);