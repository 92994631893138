import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useIntl } from "react-intl";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { StringParam, useQueryParam } from "use-query-params";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import { convertSortByFromString, convertToAttributeScalar, getGraphqlIdFromDBId, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedCollectionProductsDataQuery, TypedCollectionProductsQuery } from "./queries";
export var FilterQuerySet = {
  encode: function encode(valueObj) {
    var str = [];
    Object.keys(valueObj).forEach(function (value) {
      str.push("".concat(value, "_").concat(valueObj[value].join("_")));
    });
    return str.join(".");
  },
  decode: function decode(strValue) {
    var obj = {};
    var propsWithValues = strValue.split(".").filter(function (n) {
      return n;
    });
    propsWithValues.map(function (value) {
      var propWithValues = value.split("_").filter(function (n) {
        return n;
      });
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  }
};
export var View = function View(_ref) {
  var match = _ref.match;

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      attributeFilters = _useQueryParam4[0],
      setAttributeFilters = _useQueryParam4[1];

  var intl = useIntl();

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }
  };

  var filters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null
  };

  var variables = _objectSpread({}, filters, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    id: getGraphqlIdFromDBId(match.params.id, "Collection"),
    sortBy: convertSortByFromString(filters.sortBy)
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];
  return React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedCollectionProductsDataQuery, {
      variables: variables,
      errorPolicy: "all",
      loaderFull: true
    }, function (collectionData) {
      var canDisplayFilters = maybe(function () {
        return !!collectionData.data.attributes.edges && !!collectionData.data.collection.name;
      }, false);

      if (collectionData.data && collectionData.data.collection === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }

      return React.createElement(TypedCollectionProductsQuery, {
        variables: variables
      }, function (collectionProductsData) {
        var handleLoadMore = function handleLoadMore() {
          return collectionProductsData.loadMore(function (prev, next) {
            return _objectSpread({}, prev, {
              products: _objectSpread({}, prev.products, {
                edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
                pageInfo: next.products.pageInfo
              })
            });
          }, {
            after: collectionProductsData.data.products.pageInfo.endCursor
          });
        };

        if (canDisplayFilters) {
          return React.createElement(MetaWrapper, {
            meta: {
              description: collectionData.data.collection.seoDescription,
              title: collectionData.data.collection.seoTitle,
              type: "product.collection"
            }
          }, React.createElement(Page, {
            clearFilters: clearFilters,
            attributes: collectionData.data.attributes.edges.map(function (edge) {
              return edge.node;
            }),
            collection: collectionData.data.collection,
            displayLoader: collectionData.loading,
            hasNextPage: maybe(function () {
              return collectionProductsData.data.products.pageInfo.hasNextPage;
            }, false),
            sortOptions: sortOptions,
            activeSortOption: filters.sortBy,
            filters: filters,
            products: collectionProductsData.data.products,
            onAttributeFiltersChange: onFiltersChange,
            onLoadMore: handleLoadMore,
            activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
            onOrder: function onOrder(value) {
              setSort(value.value);
            }
          }));
        }
      });
    });
  });
};
export default View;