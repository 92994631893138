import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Money } from "@components/containers";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)), React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderList.OrderList.1116468870",
    defaultMessage: "Order Number"
  })), React.createElement(S.Description, null, "Description"), matches ? React.createElement(React.Fragment, null, React.createElement(S.Total, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderList.OrderList.878013594",
    defaultMessage: "Total"
  })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderList.OrderList.1633831221",
    defaultMessage: "Date of Order"
  })), React.createElement(S.CustOrderNumber, null, "Custom Order Number"), React.createElement(S.PlannedDeliveryDate, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderList.OrderList.4026482269",
    defaultMessage: "Planned Delivery"
  }))) : React.createElement(S.PlannedDeliveryDate, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderList.OrderList.4026482269",
    defaultMessage: "Planned Delivery"
  })));
};

export var OrderList = function OrderList(_ref) {
  var orders = _ref.orders,
      orderCounter = _ref.orderCounter;
  var theme = React.useContext(ThemeContext);
  var renderOrders = orders ? orders === null || orders === void 0 ? void 0 : orders.slice(0, orderCounter) : orders;
  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["darkOrange"] = "#ff8c00";
    statusColors["green"] = "#7fbf7f";
  })(statusColors || (statusColors = {}));

  var statusColor = function statusColor(status) {
    switch (status) {
      case 1:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "New");

      case 2:
      case 3:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Shipping");

      case 4:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoice");

      case 5:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices printed");

      case 6:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices Processed");

      case 7:
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order confirmation printed");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "None");
    }
  };

  var makeDate = function makeDate(date) {
    return date === "" || date === null || date === undefined ? "None" : React.createElement(FormattedDate, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      value: new Date(date)
    });
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches)), renderOrders ? renderOrders.map(function (order) {
      var date = new Date(order.orderDate);
      var deliverDate = makeDate(order === null || order === void 0 ? void 0 : order.deliveryDate);
      return React.createElement(Link, {
        key: order.orderId,
        to: "/order-detail/?orderId=".concat(order.orderId)
      }, React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.orderId
      }, React.createElement(S.Status, null, statusColor(order.salesState)), React.createElement(S.IndexNumber, null, order.orderNumber), React.createElement(S.Description, null, order.description), matches ? React.createElement(React.Fragment, null, React.createElement(S.Total, null, React.createElement(Money, {
        money: {
          amount: order.totalNetAmount,
          currency: "EUR"
        }
      })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        value: date
      })), React.createElement(S.CustOrderNumber, null, order.customerOrderNumber || "None"), React.createElement(S.PlannedDeliveryDate, null, deliverDate)) : React.createElement(React.Fragment, null, React.createElement(S.PlannedDeliveryDate, null, deliverDate))));
    }) : null);
  }));
};