import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { history } from "./history";
import { appInsightsInstrumentationKey, appInsightsEnv, appInsightsApp } from "./constants";
var reactPlugin = new ReactPlugin();
var ai = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsInstrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: _defineProperty({}, reactPlugin.identifier, {
      history: history
    })
  }
});
ai.loadAppInsights();

var telemetryInitializer = function telemetryInitializer(envelope) {
  /* eslint-disable */
  envelope.tags["environment"] = appInsightsEnv;
  envelope.tags["source application"] = appInsightsApp;
  /* eslint-enable */
};

ai.appInsights.addTelemetryInitializer(telemetryInitializer);
export default (function (Component) {
  return withAITracking(reactPlugin, Component);
});
var appInsights = ai.appInsights;
export { appInsights };