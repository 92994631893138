import "./scss/index.scss";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import { useAuth } from "@saleor/sdk";
import { Offline, OfflinePlaceholder, Online, OverlayContext } from "..";
import SignInForm from "./SignInForm";
import { OverlayType, OverlayTheme } from "../Overlay";

var CheckoutLogin = function CheckoutLogin() {
  var overlay = useContext(OverlayContext);

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var show = overlay.show;

  var showPasswordResetOverlay = function showPasswordResetOverlay() {
    show(OverlayType.password, OverlayTheme.right);
  };

  if (user) {
    return React.createElement(Redirect, {
      to: "/"
    });
  }

  return React.createElement("div", null, React.createElement(Online, null, React.createElement("div", {
    className: "checkout-login"
  }, React.createElement("div", {
    className: "checkout-login__user"
  }, React.createElement(SignInForm, {
    onForgottenPasswordClick: showPasswordResetOverlay
  })))), React.createElement(Offline, null, React.createElement(OfflinePlaceholder, null)));
};

export default CheckoutLogin;