import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductCategory($id: ID!) {\n    product(id: $id) {\n      category {\n        id\n        name\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../core/queries";
export var itemCategory = gql(_templateObject());
export var TypedProductCategoryQuery = TypedQuery(itemCategory);