import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { maybe } from "@utils/misc";
import ListViewCatalog from "@temp/components/HybrITComponents/ListView/ListViewCatalog";
import { FilterSidebar } from "@components/organisms";
import { CatalogCustomHeader } from "@temp/components/HybrITComponents";
import { Breadcrumbs } from "../../components";
import { slugify, getDBIdFromGraphqlId } from "../../core/utils";

var Page = function Page(_ref) {
  var activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      filters = _ref.filters,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      sortOptions = _ref.sortOptions,
      onOrder = _ref.onOrder,
      setSearch = _ref.setSearch,
      search = _ref.search,
      category = _ref.category;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var breadcrumbs = [{
    link: ["/product"].join(""),
    value: "Parts Catalog"
  }];

  if (category) {
    breadcrumbs.push({
      link: "/product/".concat(slugify(category.name), "/").concat(getDBIdFromGraphqlId(category.id, "Category")),
      value: category.name
    });
  }

  return React.createElement("div", {
    className: "collection"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(CatalogCustomHeader, {
    activeSortOption: activeSortOption,
    sortOptions: sortOptions,
    onSelectSort: onOrder,
    numberOfResults: products ? products.totalCount : 0,
    setSearch: setSearch,
    search: search
  }), canDisplayProducts && React.createElement(ListViewCatalog, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })));
};

export default Page;