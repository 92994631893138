import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Money } from "@components/containers";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var header = function header(matches, renderOnPage) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)), React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OfferTableList.OfferTableList.2325501549",
    defaultMessage: "Offer Number"
  })), React.createElement(S.Description, null, "Description"), renderOnPage === "offers" ? matches && React.createElement(React.Fragment, null, React.createElement(S.DateOfOffer, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OfferTableList.OfferTableList.3117915576",
    defaultMessage: "Date of Offer"
  }))) : null, React.createElement(S.Total, null, "Total"), renderOnPage === "offers" ? React.createElement(S.Incoterm, null, "IncoTerm") : null);
};

export var OfferTableList = function OfferTableList(_ref) {
  var offers = _ref.offers,
      offerCounter = _ref.offerCounter,
      _ref$renderOnPage = _ref.renderOnPage,
      renderOnPage = _ref$renderOnPage === void 0 ? "offers" : _ref$renderOnPage;
  var theme = React.useContext(ThemeContext);
  var renderOffers = offers ? offers.slice(0, offerCounter) : offers;
  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["orange"] = "#FFA500";
    statusColors["green"] = "#7fbf7f";
  })(statusColors || (statusColors = {}));

  var statusColor = function statusColor(status) {
    switch (status) {
      case "Submitted":
        return React.createElement("span", {
          style: {
            color: statusColors.orange
          }
        }, "Submitted");

      case "Order":
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "In Review");
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches, renderOnPage)), renderOffers ? renderOffers.map(function (offer, i) {
      var date = new Date(offer.offerDate);
      var renderDate = matches ? React.createElement(React.Fragment, null, React.createElement(S.DateOfOffer, null, React.createElement(FormattedDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        value: date
      }))) : "";
      return React.createElement(Link, {
        key: offer.offerNumber,
        to: "/offer-detail/?offerId=".concat(offer.offerId)
      }, React.createElement(S.Row, {
        "data-test": "offerEntry",
        "data-test-id": offer.offerNumber
      }, React.createElement(S.Status, null, statusColor(offer.status)), React.createElement(S.IndexNumber, null, offer.offerNumber), React.createElement(S.Description, null, offer.description), renderOnPage === "offers" ? renderDate : null, React.createElement(S.Total, null, React.createElement(Money, {
        money: {
          amount: offer.totalValue,
          currency: "EUR"
        }
      })), renderOnPage === "offers" ? React.createElement(S.Incoterm, null, offer.incoTerm) : null));
    }) : null);
  }));
};