import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Money } from "@components/containers";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var header = function header(matches, renderOnPage) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)), React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OrderTableList.OrderTableList.1116468870",
    defaultMessage: "Order Number"
  })), React.createElement(S.Description, null, "Description"), React.createElement(S.Total, null, "Total"), renderOnPage === "orders" ? matches && React.createElement(React.Fragment, null, React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OrderTableList.OrderTableList.1633831221",
    defaultMessage: "Date of Order"
  }))) : null);
};

export var OrderTableList = function OrderTableList(_ref) {
  var orders = _ref.orders,
      orderCounter = _ref.orderCounter,
      _ref$renderOnPage = _ref.renderOnPage,
      renderOnPage = _ref$renderOnPage === void 0 ? "home" : _ref$renderOnPage;
  var theme = React.useContext(ThemeContext);
  var renderOrders = orders ? orders.slice(0, orderCounter) : orders;
  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["darkOrange"] = "#ff8c00";
    statusColors["green"] = "#7fbf7f";
  })(statusColors || (statusColors = {}));

  var statusColor = function statusColor(status) {
    switch (status) {
      case 1:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "New");

      case 2:
      case 3:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Shipping");

      case 4:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoice");

      case 5:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices printed");

      case 6:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices Processed");

      case 7:
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order confirmation printed");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "None");
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches, renderOnPage)), renderOrders ? renderOrders.map(function (order) {
      var date = new Date(order.orderDate);
      var renderDate = matches ? React.createElement(React.Fragment, null, React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        value: date
      }))) : "";
      return React.createElement(Link, {
        key: order.orderId,
        to: "/order-detail/?orderId=".concat(order.orderId)
      }, React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.orderId
      }, React.createElement(S.Status, null, statusColor(order.salesState)), React.createElement(S.IndexNumber, null, order.orderNumber), React.createElement(S.Description, null, order.description), React.createElement(S.Total, null, React.createElement(Money, {
        money: {
          amount: order.totalNetAmount,
          currency: "EUR"
        }
      })), renderOnPage === "orders" ? renderDate : null));
    }) : null);
  }));
};