import * as React from "react";
import "./scss/index.scss";

var MainLogo = function MainLogo(_ref) {
  var imageLarge = _ref.imageLarge,
      imageSmall = _ref.imageSmall;
  return React.createElement("div", {
    className: "main-logo-section"
  }, React.createElement("img", {
    src: imageLarge,
    className: "image_full",
    alt: "Viscon"
  }));
};

export default MainLogo;