import "./scss/index.scss";
import React from "react";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { useIntl } from "react-intl";
import CatalogSearch from "./CatalogSearch/CatalogSearch";
import CatalogDropDown from "./CatalogDropdown/CatalogDropdown";

var CatalogCustomHeader = function CatalogCustomHeader(_ref) {
  var _ref$numberOfResults = _ref.numberOfResults,
      numberOfResults = _ref$numberOfResults === void 0 ? 0 : _ref$numberOfResults,
      activeSortOption = _ref.activeSortOption,
      sortOptions = _ref.sortOptions,
      onSelectSort = _ref.onSelectSort,
      setSearch = _ref.setSearch,
      search = _ref.search,
      spareToggle = _ref.spareToggle;
  var intl = useIntl();
  var defaultSortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];

  if (sortOptions.length <= 0) {
    sortOptions = defaultSortOptions;
  }

  return React.createElement("div", {
    className: "custom_header__container"
  }, React.createElement("div", {
    className: "header_item"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch
  })), React.createElement("div", {
    className: "header_item dropdown"
  }, React.createElement(CatalogDropDown, {
    onChange: onSelectSort,
    options: sortOptions,
    value: sortOptions.find(function (option) {
      return option.value === activeSortOption;
    })
  })), spareToggle && spareToggle, React.createElement("div", {
    className: "header_item results"
  }, "Results: ", numberOfResults));
};

export default CatalogCustomHeader;