import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { maybe } from "@utils/misc";
import { FormattedMessage } from "react-intl";
import ListViewCatalog from "@temp/components/HybrITComponents/ListView/ListViewCollectionCatalog";
import { CatalogCustomHeaderNoSortby } from "@temp/components/HybrITComponents/CatalogCustomHeaderNoSortBy";
import { FilterSidebar } from "@components/organisms";
import { MachineDetailItem } from "@temp/components/HybrITComponents/MachineList/MachineListItem";
import ReactSVG from "react-svg";
import { DocumentListItem } from "@temp/components/HybrITComponents/DocumentList/DocumentListItem/DocumentLIstItem";
import { Breadcrumbs } from "../../components";
import cancelImg from "../../images/x.svg";
import "./scss/index.scss";
export var Page = function Page(_ref) {
  var _documents$data, _documents$data$resul, _documents$data2, _documents$data2$resu;

  var attributes = _ref.attributes,
      filters = _ref.filters,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      onLoadMore = _ref.onLoadMore,
      collectionProducts = _ref.collectionProducts,
      setSearch = _ref.setSearch,
      setShowSpareParts = _ref.setShowSpareParts,
      showSpareParts = _ref.showSpareParts,
      search = _ref.search,
      machine = _ref.machine,
      machineId = _ref.machineId,
      electricalId = _ref.electricalId,
      handleMutateName = _ref.handleMutateName,
      refetch = _ref.refetch,
      documents = _ref.documents,
      serviceObjectId = _ref.serviceObjectId;
  var canDisplayProducts = maybe(function () {
    return !!collectionProducts.edges && collectionProducts.totalCount !== undefined;
  });

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var breadcrumbs = [{
    link: ["/machine"].join(""),
    value: "Machines Overview"
  }, {
    link: ["/machine-detail/?machineId=".concat(machineId)].join(""),
    value: "".concat(machine.name)
  }];

  var handleCheckBoxState = function handleCheckBoxState() {
    if (showSpareParts === "true") setShowSpareParts("false");
    if (showSpareParts === "false" || !showSpareParts) setShowSpareParts("true");
  };

  var renderItems = documents === null || documents === void 0 ? void 0 : (_documents$data = documents.data) === null || _documents$data === void 0 ? void 0 : (_documents$data$resul = _documents$data.result) === null || _documents$data$resul === void 0 ? void 0 : _documents$data$resul.map(function (doc, i) {
    return React.createElement(DocumentListItem, {
      key: "DocumentListItem-".concat(i),
      document: doc || null,
      projectId: serviceObjectId
    });
  });
  var spareToggle = React.createElement("div", {
    className: "sparePartsToggle"
  }, showSpareParts === "true" ? React.createElement("div", {
    className: "checkedBoxy",
    onClick: handleCheckBoxState
  }, React.createElement(ReactSVG, {
    path: cancelImg
  })) : React.createElement("div", {
    className: "uncheckedBoxy",
    onClick: handleCheckBoxState
  }, React.createElement(ReactSVG, {
    path: cancelImg
  })), React.createElement("div", {
    className: "spareText"
  }, React.createElement(FormattedMessage, {
    id: "views.MachineDetail.page.4294483918",
    defaultMessage: "Only spare and wear parts"
  })));

  var getPublishedProducts = function getPublishedProducts() {
    return collectionProducts.edges.filter(function (edge) {
      return edge.node.product.isPublished === true;
    });
  };

  return React.createElement("div", {
    className: "collection"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "document-overview"
  }, React.createElement("div", {
    className: "machine-detail-view"
  }, React.createElement(MachineDetailItem, {
    machine: machine,
    refetch: refetch,
    handleMutateName: handleMutateName,
    electricalId: electricalId
  })), React.createElement("div", {
    className: "machine-document-overview"
  }, React.createElement("div", {
    className: "subtitle"
  }, "Documents"), (documents === null || documents === void 0 ? void 0 : (_documents$data2 = documents.data) === null || _documents$data2 === void 0 ? void 0 : (_documents$data2$resu = _documents$data2.result) === null || _documents$data2$resu === void 0 ? void 0 : _documents$data2$resu.length) > 0 ? renderItems : "No documents available")), React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(CatalogCustomHeaderNoSortby, {
    numberOfResults: collectionProducts ? getPublishedProducts().length : 0,
    setSearch: setSearch,
    search: search,
    spareToggle: spareToggle
  }), canDisplayProducts && React.createElement(ListViewCatalog, {
    products: null,
    collectionProducts: collectionProducts,
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })));
};
export default Page;