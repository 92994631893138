import "./scss/index.scss";
import * as React from "react";
import { Link } from "react-router-dom";
import { maybe } from "@temp/core/utils";
import * as appPaths from "../../../app/routes";

var ProjectListItem = function ProjectListItem(_ref) {
  var project = _ref.project;

  var AddProjectIdTo = function AddProjectIdTo(baseUrl, id) {
    return "".concat(baseUrl, "?mainserviceobjectId=").concat(id);
  };

  var id = project.mainserviceobjectId,
      slug = project.slug,
      buildDate = project.buildDate,
      description = project.description,
      orderCustomerName = project.orderCustomerName,
      name = project.name;

  var formatDate = function formatDate(date) {
    return date ? new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }) : "None";
  };

  var orNone = function orNone(val) {
    return typeof val === "number" && val > -1 ? val : typeof val === "string" && val !== "" ? val : "None";
  };

  var metaItems = {
    orderNumber: {
      key: "Order Number",
      value: maybe(function () {
        return slug;
      }, "None")
    },
    buildDate: {
      key: "Build Date",
      value: formatDate(buildDate)
    },
    description: {
      key: "Description",
      value: orNone(description)
    },
    orderCustomerName: {
      key: "Order Customer Name",
      value: orNone(orderCustomerName)
    }
  };
  return React.createElement("div", {
    className: "project-list-item"
  }, React.createElement(Link, {
    to: AddProjectIdTo(appPaths.projectDetailUrl, id)
  }, React.createElement("div", {
    className: "project-list-item-header"
  }, React.createElement("h4", {
    className: "project-list-item-header__title"
  }, name)), React.createElement("div", {
    className: "project-list-item__metaitems-section"
  }, React.createElement("div", {
    className: "project-list-item__metaitems-section__left"
  }, React.createElement("p", null, React.createElement("span", {
    className: "project-list-item__metaitems-section__left__key"
  }, metaItems.orderNumber.key), React.createElement("span", {
    className: "project-list-item__metaitems-section__left__value"
  }, metaItems.orderNumber.value)), React.createElement("p", null, React.createElement("span", {
    className: "project-list-item__metaitems-section__left__key"
  }, metaItems.buildDate.key), React.createElement("span", {
    className: "project-list-item__metaitems-section__left__value"
  }, metaItems.buildDate.value))), React.createElement("div", {
    className: "project-list-item__metaitems-section__right"
  }, React.createElement("p", null, React.createElement("span", {
    className: "project-list-item__metaitems-section__right__key"
  }, metaItems.description.key), React.createElement("span", {
    className: "project-list-item__metaitems-section__right__value"
  }, metaItems.description.value)), React.createElement("p", null, React.createElement("span", {
    className: "project-list-item__metaitems-section__right__key"
  }, metaItems.orderCustomerName.key), React.createElement("span", {
    className: "project-list-item__metaitems-section__right__value"
  }, metaItems.orderCustomerName.value))))));
};

export default ProjectListItem;