import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Review order view showed in checkout.
 */
var CheckoutReview = function CheckoutReview(_ref) {
  var shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      paymentMethodName = _ref.paymentMethodName,
      _ref$customerOrderRef = _ref.customerOrderRefCallback,
      customerOrderRefCallback = _ref$customerOrderRef === void 0 ? function () {
    return null;
  } : _ref$customerOrderRef,
      email = _ref.email,
      errors = _ref.errors,
      checkedPaymentOption = _ref.checkedPaymentOption;

  var onCustomerOrderRefChange = function onCustomerOrderRefChange(e) {
    customerOrderRefCallback(e.target.value);
  };

  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, checkedPaymentOption === "offer" ? React.createElement(FormattedMessage, checkoutMessages.reviewOffer) : checkedPaymentOption === "order" ? React.createElement(FormattedMessage, checkoutMessages.reviewOrder) : null), React.createElement("div", {
    className: "order-reference-container"
  }, React.createElement("input", {
    type: "text",
    placeholder: "Custom order reference",
    onChange: onCustomerOrderRefChange
  })), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", {
    "data-test": "billingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.4282475982",
    defaultMessage: "Billing Address"
  })), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: billingAddress,
    email: email
  }))), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { CheckoutReview };