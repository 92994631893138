import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query OfferProductCategoryData($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      description\n      descriptionJson\n      category {\n        id\n        name\n      }\n      images {\n        id\n        url\n        alt\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../core/queries";
export var offerProductCategoryDataQuery = gql(_templateObject());
export var TypedOfferProductsCategoryDataQuery = TypedQuery(offerProductCategoryDataQuery);