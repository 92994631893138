import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OrderThankYou } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";

var OrderThankYouPage = function OrderThankYouPage(_ref) {
  _objectDestructuringEmpty(_ref);

  var location = useLocation();
  var history = useHistory();
  var _location$state = location.state,
      token = _location$state.token,
      orderNumber = _location$state.orderNumber;
  return React.createElement(OrderThankYou, {
    continueShopping: function continueShopping() {
      return history.push(BASE_URL);
    },
    orderNumber: orderNumber,
    orderDetails: function orderDetails() {
      return history.push(generateGuestOrderDetailsUrl(token));
    }
  });
};

export { OrderThankYouPage };