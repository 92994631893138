import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import CatalogSearch from "./CatalogSearch/CatalogSearch";

var CatalogCustomHeader = function CatalogCustomHeader(_ref) {
  var _ref$numberOfResults = _ref.numberOfResults,
      numberOfResults = _ref$numberOfResults === void 0 ? 0 : _ref$numberOfResults,
      setSearch = _ref.setSearch,
      search = _ref.search,
      spareToggle = _ref.spareToggle;
  return React.createElement("div", {
    className: "custom_header__container"
  }, React.createElement("div", {
    className: "header_item"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch
  })), spareToggle && spareToggle, React.createElement("div", {
    className: "header_item results"
  }, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.CatalogCustomHeaderNoSortBy.CatalogCustomHeaderNoSortby.1534910739",
    defaultMessage: "Results"
  }), ": ", numberOfResults));
};

export default CatalogCustomHeader;