import React from "react";
import "./scss/index.scss";
import { MachineListItem } from "./MachineListItem";
export var MachineList = function MachineList(_ref) {
  var machines = _ref.machines;
  return React.createElement("div", {
    className: "machine-list-view"
  }, machines && machines.map(function (machine) {
    return React.createElement(MachineListItem, {
      machine: machine.node,
      key: "MachineList_".concat(machine.node.id)
    });
  }));
};
export default MachineList;