var defaultPages = [{
  children: [],
  collection: null,
  id: "defaultProjects",
  name: "Projects",
  category: null,
  url: "/project",
  page: {
    slug: "project"
  },
  parent: null
}, {
  children: [],
  collection: null,
  id: "defaultMachines",
  name: "Machines",
  category: null,
  url: "/machine",
  page: {
    slug: "machine"
  },
  parent: null
}, {
  children: [],
  collection: null,
  id: "defaultParts",
  name: "Parts Catalog",
  category: null,
  url: "/product",
  page: {
    slug: "product"
  },
  parent: null
}, {
  children: [],
  collection: null,
  id: "defaultOffers",
  name: "Offers",
  category: null,
  url: "/offers/",
  page: {
    slug: "offers"
  },
  parent: null
}, {
  children: [],
  collection: null,
  id: "defaultOrders",
  name: "Order history",
  category: null,
  url: "/order-history/",
  page: {
    slug: "order"
  },
  parent: null
}];
export default defaultPages;