import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { OfflinePlaceholder, NotFound } from "@temp/components";
import { useQueryParam, StringParam } from "use-query-params";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { useIntl } from "react-intl";
import { convertSortByFromString, convertToAttributeScalar, maybe, getGraphqlIdFromDBId } from "../../core/utils";
import NetworkStatus from "../../components/NetworkStatus";
import { TypedCategoryDataQuery, TypedProductsDataQuery } from "./queries";
import Page from "./Page";
import { FilterQuerySet } from "../Category/View";
import { PRODUCTS_PER_PAGE } from "../../core/config";
export var View = function View(_ref) {
  var match = _ref.match;

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("search", StringParam),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      search = _useQueryParam4[0],
      setSearch = _useQueryParam4[1];

  var _useQueryParam5 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam6 = _slicedToArray(_useQueryParam5, 2),
      attributeFilters = _useQueryParam6[0],
      setAttributeFilters = _useQueryParam6[1];

  var intl = useIntl();

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }
  };

  var categoryFilter = {
    id: match.params.id ? getGraphqlIdFromDBId(match.params.id, "Category") : null
  };
  var filters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null
  };

  var variables = _objectSpread({}, filters, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    categories: categoryFilter.id === null ? [] : [categoryFilter.id],
    sortBy: convertSortByFromString(filters.sortBy),
    search: search || null
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];
  return React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedProductsDataQuery, {
      errorPolicy: "all",
      loaderFull: true,
      variables: variables
    }, function (productsData) {
      var handleLoadMore = function handleLoadMore() {
        return productsData.loadMore(function (prev, next) {
          return _objectSpread({}, prev, {
            products: _objectSpread({}, prev.products, {
              edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
              pageInfo: next.products.pageInfo
            })
          });
        }, {
          after: productsData.data.products.pageInfo.endCursor
        });
      };

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }

      if (productsData.data && productsData.data.products === null) {
        return React.createElement(NotFound, null);
      }

      if (categoryFilter.id !== null) {
        return React.createElement(TypedCategoryDataQuery, {
          loaderFull: true,
          errorPolicy: "all",
          variables: categoryFilter
        }, function (categoryResult) {
          return React.createElement(Page, {
            attributes: [{
              id: "null",
              name: "NoAttributes",
              slug: "NoSlug",
              values: [{
                id: "null",
                name: "NoSingleAttribute",
                slug: "NoSingleSlug",
                selected: false
              }]
            }],
            sortOptions: sortOptions,
            onOrder: function onOrder(value) {
              setSort(value.value);
            },
            activeSortOption: filters.sortBy,
            activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
            category: categoryResult.data.category,
            filters: filters,
            clearFilters: clearFilters,
            displayLoader: productsData.loading,
            onLoadMore: handleLoadMore,
            hasNextPage: maybe(function () {
              return productsData.data.products.pageInfo.hasNextPage;
            }, false),
            products: productsData.data.products,
            onAttributeFiltersChange: onFiltersChange,
            setSearch: setSearch,
            search: search
          });
        });
      }

      return React.createElement(Page, {
        attributes: [{
          id: "null",
          name: "NoAttributes",
          slug: "NoSlug",
          values: [{
            id: "null",
            name: "NoSingleAttribute",
            slug: "NoSingleSlug",
            selected: false
          }]
        }],
        sortOptions: sortOptions,
        onOrder: function onOrder(value) {
          setSort(value.value);
        },
        activeSortOption: filters.sortBy,
        activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
        filters: filters,
        clearFilters: clearFilters,
        displayLoader: productsData.loading,
        onLoadMore: handleLoadMore,
        hasNextPage: maybe(function () {
          return productsData.data.products.pageInfo.hasNextPage;
        }, false),
        products: productsData.data.products,
        onAttributeFiltersChange: onFiltersChange,
        setSearch: setSearch,
        search: search
      });
    });
  });
};
export default View;