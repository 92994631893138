import React from "react";
import "./scss/index.scss";
import { FormattedMessage } from "react-intl";
import { useHistory, Link } from "react-router-dom";
import { Button, Loader } from "@components/atoms";
import { OrderTableList } from "./OrderTableList/OrderTableList";
import { OfferTableList } from "./OfferTableList/OfferTableList";
export default function OrderOfferTable(_ref) {
  var orders = _ref.orders,
      offers = _ref.offers,
      offersLoading = _ref.offersLoading,
      ordersLoading = _ref.ordersLoading;
  var history = useHistory();
  var showMaxRecords = 5;
  return React.createElement("div", {
    className: "order-offer-table-container"
  }, React.createElement("div", {
    className: "table-spacer pad-right"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OrderOfferTable.1624610867",
    defaultMessage: "Offers"
  })), offersLoading ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, (offers === null || offers === void 0 ? void 0 : offers.length) > 0 ? React.createElement(React.Fragment, null, " ", React.createElement("div", {
    className: "table-offer-section"
  }, React.createElement(OfferTableList, {
    offers: offers,
    offerCounter: showMaxRecords,
    history: history,
    renderOnPage: "home"
  })), React.createElement("div", {
    className: "show-more-link-button"
  }, React.createElement(Link, {
    to: "/offers"
  }, React.createElement(Button, {
    testingContext: "navigationButton",
    className: "button cart navigation"
  }, "Show all offers")))) : "No offers found")), React.createElement("div", {
    className: "table-spacer pad-left"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OrderOfferTable.OrderOfferTable.2364847682",
    defaultMessage: "Orders"
  })), ordersLoading ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, (orders === null || orders === void 0 ? void 0 : orders.length) > 0 ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "table-order-section"
  }, React.createElement(OrderTableList, {
    orders: orders,
    orderCounter: showMaxRecords,
    history: history,
    renderOnPage: "home"
  })), React.createElement("div", {
    className: "show-more-link-button"
  }, React.createElement(Link, {
    to: "/order-history"
  }, React.createElement(Button, {
    testingContext: "navigationButton",
    className: "button cart navigation"
  }, "Show all orders")))) : "No orders found")));
}