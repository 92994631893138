import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query AlternativeProductDetails($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      metadata {\n        key\n        value\n      }\n      category {\n        id\n        name\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ReplacementProductDetails($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      metadata {\n        key\n        value\n      }\n      category {\n        id\n        name\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../../core/queries";
export var replacementProductDetailsQuery = gql(_templateObject());
export var alternativeProductDetailsQuery = gql(_templateObject2());
export var TypedReplacementProductDetailsDataQuery = TypedQuery(replacementProductDetailsQuery);
export var TypedAlternativeProductDetailsDataQuery = TypedQuery(alternativeProductDetailsQuery);