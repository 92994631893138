import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import React from "react";
import { getBackgroundColor } from "@utils/styles";
import * as S from "./styles";
import { InputLabel } from "../InputLabel";
/**
 * Wrapper component for Stripe input elements.
 */

var StripeInputElement = function StripeInputElement(_ref) {
  var _options$style, _options$style2;

  var onBlur = _ref.onBlur,
      onFocus = _ref.onFocus,
      _ref$contentLeft = _ref.contentLeft,
      contentLeft = _ref$contentLeft === void 0 ? null : _ref$contentLeft,
      _ref$contentRight = _ref.contentRight,
      contentRight = _ref$contentRight === void 0 ? null : _ref$contentRight,
      _ref$error = _ref.error,
      error = _ref$error === void 0 ? false : _ref$error,
      placeholder = _ref.placeholder,
      label = _ref.label,
      onChange = _ref.onChange,
      type = _ref.type,
      options = _ref.options,
      props = _objectWithoutProperties(_ref, ["onBlur", "onFocus", "contentLeft", "contentRight", "error", "placeholder", "label", "onChange", "type", "options"]);

  var elementRef = React.useRef(null);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      filled = _React$useState2[0],
      setFilled = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      active = _React$useState4[0],
      setActive = _React$useState4[1];

  var _React$useState5 = React.useState("transparent"),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      labelBackground = _React$useState6[0],
      setColor = _React$useState6[1];

  var OPTIONS = _objectSpread({}, options, {
    style: _objectSpread({}, options === null || options === void 0 ? void 0 : options.style, {
      base: _objectSpread({
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: active && !filled ? "#aaa" : "transparent",
          visibility: active && !filled ? "visible" : "hidden"
        },
        color: "#111",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        fontWeight: "500",
        iconColor: "#c4f0ff"
      }, options === null || options === void 0 ? void 0 : (_options$style = options.style) === null || _options$style === void 0 ? void 0 : _options$style.base),
      invalid: _objectSpread({
        color: "#ffc7ee",
        iconColor: "#ffc7ee"
      }, options === null || options === void 0 ? void 0 : (_options$style2 = options.style) === null || _options$style2 === void 0 ? void 0 : _options$style2.invalid)
    })
  });

  React.useEffect(function () {
    if (elementRef) {
      var color = getBackgroundColor(elementRef.current);
      setColor(color);
    }
  }, []);
  var handleFocus = React.useCallback(function () {
    setActive(true);

    if (onFocus) {
      onFocus();
    }
  }, [setActive, onFocus]);
  var handleBlur = React.useCallback(function () {
    setActive(false);

    if (onBlur) {
      onBlur();
    }
  }, [setActive, onBlur]);

  var handleStripeElementChange = function handleStripeElementChange(event) {
    setFilled(!(event === null || event === void 0 ? void 0 : event.empty));

    if (onChange) {
      onChange(event);
    }
  };

  var renderStripeElement = function renderStripeElement() {
    switch (type) {
      case "CardNumber":
        return React.createElement(CardNumberElement, _extends({}, props, {
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: handleStripeElementChange,
          options: OPTIONS
        }));

      case "CardExpiry":
        return React.createElement(CardExpiryElement, _extends({}, props, {
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: handleStripeElementChange,
          options: OPTIONS
        }));

      case "CardCvc":
        return React.createElement(CardCvcElement, _extends({}, props, {
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: handleStripeElementChange,
          options: OPTIONS
        }));

      default:
        throw new Error("Unsupported stripe element");
    }
  };

  return React.createElement(S.Wrapper, {
    active: active,
    error: error,
    ref: elementRef
  }, contentLeft && React.createElement(S.Content, null, contentLeft), React.createElement(S.InputWrapper, null, renderStripeElement(), label && React.createElement(InputLabel, {
    labelBackground: labelBackground,
    active: active || !!filled
  }, label)), contentRight && React.createElement(S.Content, null, contentRight));
};

export { StripeInputElement };