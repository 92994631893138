import "./scss/index.scss";
import * as React from "react";
import { SocialMediaIcon } from "..";
import { SOCIAL_MEDIA } from "../../core/config";
import Nav from "./Nav";

var Footer = function Footer() {
  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement("div", {
    className: "footer__favicons"
  }, SOCIAL_MEDIA.map(function (medium) {
    return React.createElement(SocialMediaIcon, {
      medium: medium,
      key: medium.ariaLabel
    });
  })), React.createElement(Nav, null));
};

export default Footer;