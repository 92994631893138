import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import ReactSVG from "react-svg";
import editIcon from "../../../../images/pencil.svg";
import "../scss/index.scss";
import "./scss/index.scss";

var ItemBody = function ItemBody(_ref) {
  var itemKey = _ref.itemKey,
      value = _ref.value;
  return itemKey ? React.createElement("div", {
    className: "machine-list-view-item__content__meta__item"
  }, React.createElement("span", {
    className: "machine-list-view-item__content__meta__item__key"
  }, itemKey || null), React.createElement("span", {
    className: "machine-list-view-item__content__meta__item__value"
  }, value || "None")) : null;
};

var FormatMetaItem = function FormatMetaItem(_ref2) {
  var key = _ref2.key,
      value = _ref2.value;

  switch (key) {
    case "mso_buildDate":
      return React.createElement(ItemBody, {
        itemKey: "Build Year",
        value: value.split("-")[0]
      });

    case "so_type":
      return React.createElement(ItemBody, {
        itemKey: "Type",
        value: value
      });

    case "so_machineCustomerName":
      return React.createElement(ItemBody, {
        itemKey: "Machine Cust. Name",
        value: value
      });

    default:
      return null;
  }
};

var extractMachineNumberFrom = function extractMachineNumberFrom() {
  var machineName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var elementClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var firstWord = machineName.split(" ").filter(function (word) {
    return word !== "" && word !== " ";
  })[0];
  var restOfSentence = machineName.split(" ").slice(2, machineName.split(" ").length).join(" ");
  var element = React.createElement(React.Fragment, null, React.createElement("p", {
    className: "".concat(elementClass)
  }, firstWord), React.createElement("span", null, restOfSentence));

  if (typeof parseInt(firstWord, 10) === "number") {
    return element;
  }

  return machineName;
};

var MetaDataItem = function MetaDataItem(_ref3) {
  var metadataItem = _ref3.metadataItem;
  return FormatMetaItem(metadataItem);
};

var MetaDataBlocks = function MetaDataBlocks(_ref4) {
  var metadata = _ref4.metadata;
  if (metadata === undefined || metadata.length === 0) return React.createElement("div", null, "No Meta Data found for this product");
  return React.createElement("div", {
    className: "machine-attributes"
  }, metadata && metadata.map(function (metadataItem) {
    return React.createElement(MetaDataItem, {
      key: "MetaItem_".concat(metadataItem.key),
      metadataItem: metadataItem
    });
  }));
}; // <---- begin of detail version ---->
// ####
// ####


var DetailItemBody = function DetailItemBody(_ref5) {
  var itemKey = _ref5.itemKey,
      value = _ref5.value;
  return itemKey ? React.createElement("div", {
    className: "machine-detail-item__content__meta__item"
  }, React.createElement("span", {
    className: "machine-detail-item__content__meta__item__key"
  }, itemKey || null), React.createElement("span", {
    className: "machine-detail-item__content__meta__item__value"
  }, value || "None")) : null;
};

var DetailFormatMetaItem = function DetailFormatMetaItem(_ref6) {
  var key = _ref6.key,
      value = _ref6.value,
      electricalId = _ref6.electricalId,
      editableValue = _ref6.editableValue,
      mutateName = _ref6.mutateName;

  var createLinkFrom = function createLinkFrom(val) {
    var link = React.createElement(Link, {
      to: "/machine-detail/?machineId=".concat(electricalId)
    }, React.createElement("div", {
      id: "electricalServiceObjectLink"
    }, val));
    return val === "None" || val === "" || !val || typeof val !== "string" ? "None" : link;
  };

  switch (key) {
    case "mso_buildDate":
      return React.createElement(DetailItemBody, {
        itemKey: "Build Year",
        value: value.split("-")[0]
      });

    case "so_type":
      return React.createElement(DetailItemBody, {
        itemKey: "Type",
        value: value
      });

    case "so_machineCustomerName":
      return React.createElement(React.Fragment, null, React.createElement(DetailItemBody, {
        itemKey: "Customer Machine Name",
        value: React.createElement("div", {
          className: "machine-detail-item__content__meta__item__value__machine-name"
        }, editableValue(value, mutateName))
      }));

    case "so_electricalServiceObject":
      return React.createElement(DetailItemBody, {
        itemKey: "Electrical Service Object",
        value: createLinkFrom(value)
      });

    default:
      return null;
  }
};

var DetailMetaDataItem = function DetailMetaDataItem(_ref7) {
  var editableValue = _ref7.editableValue,
      metadataItem = _ref7.metadataItem,
      mutateName = _ref7.mutateName;
  var key = metadataItem.key,
      value = metadataItem.value,
      electricalId = metadataItem.electricalId;
  return DetailFormatMetaItem({
    key: key,
    value: value,
    electricalId: electricalId,
    editableValue: editableValue,
    mutateName: mutateName
  });
};

var DetailMetaDataBlocks = function DetailMetaDataBlocks(_ref8) {
  var metadata = _ref8.metadata,
      electricalId = _ref8.electricalId,
      editableValue = _ref8.editableValue,
      mutateName = _ref8.mutateName;
  if (metadata === undefined || metadata.length === 0) return React.createElement("div", null, "No Meta Data found for this product");
  return React.createElement("div", {
    className: "machine-attributes"
  }, metadata && metadata.map(function (metadataItem) {
    return React.createElement(DetailMetaDataItem, {
      key: "MetaItem_".concat(metadataItem.key),
      metadataItem: _objectSpread({}, metadataItem, {
        electricalId: electricalId
      }),
      editableValue: editableValue,
      mutateName: mutateName
    });
  }));
};

export function MachineDetailItem(_ref9) {
  var _machine$metadata;

  var machine = _ref9.machine,
      electricalId = _ref9.electricalId,
      handleMutateName = _ref9.handleMutateName,
      refetch = _ref9.refetch;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      machineName = _React$useState2[0],
      setMachineName = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showDetailInput = _React$useState4[0],
      setShowDetailInput = _React$useState4[1];

  var machine_slug = machine === null || machine === void 0 ? void 0 : machine.slug;
  var machine_id = machine === null || machine === void 0 ? void 0 : machine.metadata.find(function (obj) {
    return obj.key === "so_serviceObjectId";
  }).value;
  var nameMutationProps = {
    name: machineName || "",
    machine_id: machine_id,
    slug: machine_slug
  };

  var mutateName = function mutateName(name) {
    if (name) handleMutateName(_objectSpread({}, nameMutationProps, {
      name: name
    }));
    handleMutateName(_objectSpread({}, nameMutationProps));
    refetch();
  };

  var setFocus = React.useCallback(function (inputElement) {
    if (inputElement) {
      inputElement.focus();
    }
  }, []); // <---------->
  // click outside functionality

  function useOutsideAlerter(ref) {
    React.useEffect(function () {
      /**
       * action if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDetailInput(false);
          mutateName(machineName);
        }
      } // Bind the event listener


      document.addEventListener("mousedown", handleClickOutside);
      return function () {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, machineName]);
  }

  var wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef); // click outside functionality
  // <---------->

  var handleNameInput = function handleNameInput(e) {
    e.preventDefault();

    if (typeof machineName !== "string") {
      return null;
    }

    setMachineName(e.target.value);
  };

  var handleMachineNameInputSubmit = function handleMachineNameInputSubmit(e) {
    e.preventDefault();
    setShowDetailInput(false);
  };

  var NameInput = function NameInput(_ref10) {
    var mutateName = _ref10.mutateName,
        namePlaceholder = _ref10.namePlaceholder;
    return React.createElement("div", {
      ref: wrapperRef
    }, React.createElement("form", {
      onSubmit: function onSubmit(e) {
        e.preventDefault();
        mutateName();
        handleMachineNameInputSubmit(e);
      }
    }, React.createElement("input", {
      type: "text",
      className: "new-machine-name-input",
      value: machineName || "",
      onChange: handleNameInput,
      placeholder: namePlaceholder || "Machine name",
      ref: setFocus
    })));
  };

  var handleNameClick = function handleNameClick() {
    if (machineName.length < 1) setMachineName("");
    setShowDetailInput(!showDetailInput);
  };

  var editableValue = function editableValue(value, mutateName) {
    return showDetailInput ? React.createElement(NameInput, {
      mutateName: mutateName,
      namePlaceholder: value
    }) : React.createElement(React.Fragment, null, value || "none", React.createElement(ReactSVG, {
      path: editIcon,
      onClick: handleNameClick
    }));
  };

  var description = "No description available";
  var descriptionInMetadata = (_machine$metadata = machine.metadata) === null || _machine$metadata === void 0 ? void 0 : _machine$metadata.find(function (m) {
    return m.key === "mso_description";
  });

  if (descriptionInMetadata && descriptionInMetadata.value) {
    description = descriptionInMetadata.value;
  }

  var machineDetailItem = React.createElement(React.Fragment, null, React.createElement("div", {
    className: "machine-detail-item"
  }, React.createElement("div", {
    className: "machine-detail-item__container"
  }, React.createElement("div", {
    className: "machine-detail-item__image"
  }, React.createElement(Thumbnail, {
    source: {
      thumbnail: machine.backgroundImage !== null ? {
        url: machine.backgroundImage.url,
        alt: ""
      } : null,
      thumbnail2x: null
    }
  })), React.createElement("div", {
    className: "machine-detail-item__content"
  }, React.createElement("h3", {
    className: "machine-detail-item__content__title"
  }, extractMachineNumberFrom(machine.name, "machine-detail-title-number")), React.createElement("p", {
    className: "machine-detail-description"
  }, description), React.createElement("div", {
    className: "machine-detail-item__content__meta"
  }, React.createElement(DetailMetaDataBlocks, {
    key: "Meta_".concat(machine.id),
    metadata: machine.metadata,
    electricalId: electricalId,
    editableValue: editableValue,
    mutateName: mutateName
  }))))));
  return machineDetailItem;
} // ####
// ####
// <---- end of detail version ---->

export default function MachineListItem(_ref11) {
  var machine = _ref11.machine;
  var machineListItem = React.createElement("div", {
    className: "machine-list-view-item"
  }, React.createElement(Link, {
    to: "/machine-detail/?machineId=".concat(machine.id),
    key: "Link_".concat(machine.id)
  }, React.createElement("div", {
    className: "machine-list-view-item__container"
  }, React.createElement("div", {
    className: "machine-list-view-item__image"
  }, React.createElement(Thumbnail, {
    source: {
      thumbnail: machine.backgroundImage !== null ? {
        url: machine.backgroundImage.url,
        alt: ""
      } : null,
      thumbnail2x: null
    }
  })), React.createElement("div", {
    className: "machine-list-view-item__content"
  }, React.createElement("h3", {
    className: "machine-list-view-item__content__title"
  }, extractMachineNumberFrom(machine.name, "machine-overview-title-number")), React.createElement("div", {
    className: "machine-list-view-item__content__meta"
  }, React.createElement(MetaDataBlocks, {
    key: "Meta_".concat(machine.id),
    metadata: machine.metadata
  }))))));
  return machineListItem;
}