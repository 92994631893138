import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon, IconButton } from "@components/atoms";
import { CachedImage, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { generateProductUrl, generateProductWithCategoryUrl } from "../../../../core/utils";
import * as S from "./styles";

var QuantityButtons = function QuantityButtons(add, subtract, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: subtract,
    "data-test": "subtractButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "horizontal_line"
  })), React.createElement("div", {
    onClick: add,
    "data-test": "increaseButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "plus"
  })));
};
/**
 * Product row displayed on cart page
 */


export var CartRow = function CartRow(_ref) {
  var index = _ref.index,
      totalPrice = _ref.totalPrice,
      unitPrice = _ref.unitPrice,
      name = _ref.name,
      sku = _ref.sku,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      thumbnail = _ref.thumbnail,
      category = _ref.category,
      _ref$attributes = _ref.attributes,
      attributes = _ref$attributes === void 0 ? [] : _ref$attributes,
      onRemove = _ref.onRemove,
      id = _ref.id;

  var _useState = useState(quantity.toString()),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  var intl = useIntl();

  var handleBlurQuantityInput = function handleBlurQuantityInput() {
    var newQuantity = parseFloat(tempQuantity.replace(",", "."));
    console.log(newQuantity);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    var newTempQuantity = newQuantity.toString();

    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }
  };

  useEffect(function () {
    setTempQuantity(quantity.toString());
  }, [quantity]);
  var add = React.useCallback(function () {
    return quantity < maxQuantity && onQuantityChange(quantity + 1);
  }, [quantity]);
  var subtract = React.useCallback(function () {
    return quantity > 1 && onQuantityChange(quantity - 1);
  }, [quantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    setTempQuantity(evt.target.value);
  };

  var productUrl = generateProductUrl(id, name);

  if (category && category.id && category.name) {
    productUrl = generateProductWithCategoryUrl(id, name, category.id, category.name);
  }

  return React.createElement(S.Wrapper, {
    "data-test": "cartRow",
    "data-test-id": sku
  }, React.createElement(S.Photo, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(CachedImage, _extends({
    "data-test": "itemImage"
  }, thumbnail)))), React.createElement(S.Description, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Name, {
    "data-test": "itemName"
  }, name)), React.createElement(S.Sku, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.sku), ":", " ", React.createElement("span", {
    "data-test": "itemSKU"
  }, sku || "-"))), React.createElement(S.Attributes, {
    "data-test": "itemAttributes"
  }, attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement(S.SingleAttribute, {
      key: "cartRow_attr_".concat(attribute.id, "_").concat(attributeIndex)
    }, React.createElement("span", {
      "data-test": "itemSingleAttribute",
      "data-test-id": attributeIndex
    }, React.createElement(S.LightFont, null, attribute.name, ":"), " ", values.map(function (value, i) {
      return React.createElement("span", {
        key: "val__".concat(i)
      }, value.name);
    }).join(", ")));
  }))), React.createElement(S.Quantity, null, React.createElement(TextField, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.qty),
    value: tempQuantity,
    onBlur: handleBlurQuantityInput,
    onChange: handleQuantityChange,
    contentRight: QuantityButtons(add, subtract, index)
  })), React.createElement(S.Trash, null, React.createElement(IconButton, {
    testingContext: "removeButton",
    testingContextId: sku,
    size: 22,
    name: "trash",
    onClick: onRemove
  })), React.createElement(S.TotalPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.totalPrice), ":")), React.createElement("p", {
    "data-test": "totalPrice"
  }, totalPrice)), React.createElement(S.UnitPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.price), ":")), React.createElement("p", {
    "data-test": "unitPrice"
  }, unitPrice)));
};