import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Loader } from "@components/atoms";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import { MachineList } from "@temp/components/HybrITComponents";
import { DocumentListItem } from "../../components/HybrITComponents/DocumentList/DocumentListItem/DocumentLIstItem";
import { Breadcrumbs, Button } from "../../components";
import * as appPaths from "../../app/routes";

var Page = function Page(_ref) {
  var _documents$data, _documents$data$resul, _documents$data2, _documents$data2$resu;

  var machines = _ref.machines,
      documents = _ref.documents,
      project = _ref.project,
      showAllMachinesButton = _ref.showAllMachinesButton,
      displayLoader = _ref.displayLoader;
  var renderItems = documents === null || documents === void 0 ? void 0 : (_documents$data = documents.data) === null || _documents$data === void 0 ? void 0 : (_documents$data$resul = _documents$data.result) === null || _documents$data$resul === void 0 ? void 0 : _documents$data$resul.map(function (doc, i) {
    return React.createElement(DocumentListItem, {
      key: "".concat(doc, "_").concat(i),
      document: doc || [],
      projectId: project.mainserviceobjectId
    });
  });

  var populateBreadcrumbs = function populateBreadcrumbs() {
    return [{
      link: ["/project"].join(""),
      value: "Projects"
    }, {
      link: "/project-detail/?mainserviceobjectId=".concat(project.mainserviceobjectId),
      value: project.name
    }];
  };

  var formatDate = function formatDate(date) {
    return date ? new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }) : "None";
  };

  return React.createElement("div", {
    className: "collection"
  }, React.createElement("div", {
    className: "container project-detail"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs()
  }), React.createElement("div", {
    className: "project-info"
  }, React.createElement("div", {
    className: "item"
  }, React.createElement("div", {
    className: "key"
  }, "Order Number"), React.createElement("div", {
    className: "value"
  }, project.slug ? project.slug : "None")), React.createElement("div", {
    className: "item"
  }, React.createElement("div", {
    className: "key"
  }, "Build Date"), React.createElement("div", {
    className: "value"
  }, formatDate(project.buildDate))), React.createElement("div", {
    className: "item"
  }, React.createElement("div", {
    className: "key"
  }, "Description"), React.createElement("div", {
    className: "value"
  }, !project.description || project.description.startsWith("{\\rtf1") ? "None" : project.description)), React.createElement("div", {
    className: "item"
  }, React.createElement("div", {
    className: "key"
  }, "Order Customer Number"), React.createElement("div", {
    className: "value"
  }, project.orderCustomerName ? project.orderCustomerName : "None"))), React.createElement("div", {
    className: "document-overview"
  }, React.createElement("div", {
    className: "subtitle"
  }, "Documents"), (documents === null || documents === void 0 ? void 0 : (_documents$data2 = documents.data) === null || _documents$data2 === void 0 ? void 0 : (_documents$data2$resu = _documents$data2.result) === null || _documents$data2$resu === void 0 ? void 0 : _documents$data2$resu.length) > 0 ? renderItems : "No documents available"), React.createElement("div", {
    className: "subtitle"
  }, "Machines"), !displayLoader ? React.createElement(React.Fragment, null, machines && machines.length > 0 ? React.createElement(MachineList, {
    machines: machines
  }) : React.createElement(VisconNotFound, {
    message: "No machines were found in this project.",
    headerMsg: "No Machines Found",
    buttonText: "Back to projects",
    buttonUrl: "/project"
  }), showAllMachinesButton && React.createElement("div", {
    className: "machines-button__container machine-button"
  }, React.createElement(Link, {
    to: "".concat(appPaths.machinesUrl, "?mainserviceobjectId=").concat(project.mainserviceobjectId)
  }, React.createElement(Button, {
    testingContext: "showAllMachinesButton"
  }, React.createElement(FormattedMessage, {
    id: "views.ProjectDetail.page.270014771",
    defaultMessage: "To machines"
  }))))) : React.createElement(Loader, null)));
};

export default Page;