import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { maybe } from "@utils/tsUtils";
import { OfflinePlaceholder, NotFound } from "@temp/components";
import formatSlug from "@temp/components/HybrITComponents/QRScanner/util/formatSlug";
import NetworkStatus from "../../components/NetworkStatus";
import { TypedMachinesQuery, TypedMachinesPerProjectQuery, TypedProjectSelectQuery } from "./queries";
import Page from "./Page";
export var View = function View() {
  var _useQueryParam = useQueryParam("search", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      search = _useQueryParam2[0],
      setSearch = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("mainserviceobjectId", StringParam),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      mainserviceobjectId = _useQueryParam4[0],
      setMainserviceobjectId = _useQueryParam4[1];

  var formattedSearch = formatSlug(search || "");
  var variables = {
    search: formattedSearch || null,
    mainserviceobjectId: mainserviceobjectId
  };

  var handleLoadMore = function handleLoadMore(queryResult) {
    return queryResult.loadMore(function (prev, next) {
      return _objectSpread({}, prev, {
        collections: _objectSpread({}, prev.collections, {
          edges: [].concat(_toConsumableArray(prev.collections.edges), _toConsumableArray(next.collections.edges)),
          pageInfo: next.collections.pageInfo
        })
      });
    }, {
      after: queryResult.data.collections.pageInfo.endCursor
    });
  };

  var handleNetworkStatus = function handleNetworkStatus(isOnline, queryResult) {
    if (!isOnline) {
      return React.createElement(OfflinePlaceholder, null);
    }

    if (!queryResult.data) {
      return React.createElement(NotFound, null);
    }
  };

  var mapProjectSelectFields = function mapProjectSelectFields(fields) {
    return [{
      label: "All machines",
      value: null
    }].concat(_toConsumableArray(fields.data.projects.edges.map(function (edge) {
      return {
        label: edge.node.name,
        value: edge.node.mainserviceobjectId
      };
    })));
  };

  var findProjectName = function findProjectName(queryResult) {
    var _queryResult$data, _queryResult$data$pro;

    return (queryResult === null || queryResult === void 0 ? void 0 : queryResult.data) ? queryResult === null || queryResult === void 0 ? void 0 : (_queryResult$data = queryResult.data) === null || _queryResult$data === void 0 ? void 0 : (_queryResult$data$pro = _queryResult$data.projects) === null || _queryResult$data$pro === void 0 ? void 0 : _queryResult$data$pro.edges.find(function (edge) {
      return edge.node.mainserviceobjectId === mainserviceobjectId;
    }) : " ";
  };

  var MachinesPerProject = React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedMachinesPerProjectQuery, {
      errorPolicy: "all",
      loaderFull: true,
      fetchPolicy: "network-only",
      variables: variables
    }, function (projectMachines) {
      var machines = projectMachines.data.collections.edges;
      handleNetworkStatus(isOnline, projectMachines);
      return React.createElement(TypedProjectSelectQuery, {
        errorPolicy: "all",
        loaderFull: true
      }, function (fields) {
        var _findProjectName;

        var projectSelectFields = mapProjectSelectFields(fields);
        var projectName = "Machines For ".concat((_findProjectName = findProjectName(fields)) === null || _findProjectName === void 0 ? void 0 : _findProjectName.node.name);
        return React.createElement(Page, {
          displayLoader: projectMachines.loading,
          projectName: projectName,
          activeProjectSelectOption: mainserviceobjectId,
          onFilterByProject: function onFilterByProject(value) {
            setMainserviceobjectId(value.value);
          },
          projectSelectFields: projectSelectFields,
          machines: machines,
          setSearch: setSearch,
          search: search,
          onLoadMore: function onLoadMore() {
            return handleLoadMore(projectMachines);
          },
          hasNextPage: maybe(function () {
            return projectMachines.data.collections.pageInfo.hasNextPage;
          }, false)
        });
      });
    });
  });
  var AllMachines = React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedMachinesQuery, {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      loaderFull: true,
      variables: variables
    }, function (allMachines) {
      var machines = allMachines.data.collections.edges;
      handleNetworkStatus(isOnline, allMachines);
      return React.createElement(TypedProjectSelectQuery, {
        errorPolicy: "all",
        loaderFull: true
      }, function (fields) {
        var projectSelectFields = mapProjectSelectFields(fields);
        var projectName = "Machines";
        return React.createElement(Page, {
          displayLoader: allMachines.loading,
          projectName: projectName,
          activeProjectSelectOption: mainserviceobjectId,
          onFilterByProject: function onFilterByProject(value) {
            setMainserviceobjectId(value.value);
          },
          projectSelectFields: projectSelectFields,
          machines: machines,
          setSearch: setSearch,
          search: search,
          onLoadMore: function onLoadMore() {
            return handleLoadMore(allMachines);
          },
          hasNextPage: maybe(function () {
            return allMachines.data.collections.pageInfo.hasNextPage;
          }, false)
        });
      });
    });
  });
  return mainserviceobjectId ? MachinesPerProject : AllMachines;
};
export default View;