import _typeof from "@babel/runtime/helpers/typeof";
import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import OfferInformationList from "../../components/HybrITComponents/OfferInformationList/OfferInformationList";
import OfferShippedProductItem from "../../components/HybrITComponents/OfferShippedProductItem/OfferShippedProductItem";
import { Breadcrumbs } from "../../components";

var handleVisconNotFound = function handleVisconNotFound(data, d) {
  return data === null || data === undefined || _typeof(data) !== "object" ? React.createElement(VisconNotFound, {
    message: "Sorry, the offer can not be displayed at the moment",
    messageTwo: "Please try again at a later moment.",
    headerMsg: "Offer cannot be displayed",
    buttonText: "Back to offers",
    buttonUrl: "/offers"
  }) : false;
};

export default function Page(_ref) {
  var offer = _ref.offer,
      products = _ref.products,
      status = _ref.status;
  var title = offer && offer.description ? "Offer - ".concat(offer.description.toString()) : "Offer - ";

  var populateBreadcrumbs = function populateBreadcrumbs(offer) {
    return [{
      link: ["/offers"].join(""),
      value: "Offers"
    }, {
      link: "/offer-detail/?offerId=".concat(offer && offer.offerId),
      value: title
    }];
  };

  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["orange"] = "#FFA500";
    statusColors["green"] = "#7fbf7f";
  })(statusColors || (statusColors = {}));

  var StatusColor = function StatusColor(_ref2) {
    var status = _ref2.status;

    switch (status) {
      case "Submitted":
        return React.createElement("span", {
          style: {
            color: statusColors.orange
          }
        }, "Submitted");

      case "Order":
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "In Review");
    }
  };

  return React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "offer-detail-page"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(offer)
  }), React.createElement("h3", {
    className: "offer-detail-page__title"
  }, title), offer && offer.reference ? React.createElement("div", null, React.createElement("span", {
    className: "field-info-key"
  }, "Reference:"), " ", offer.reference) : null, handleVisconNotFound(offer) || React.createElement(React.Fragment, null, React.createElement("div", null, React.createElement("span", {
    className: "field-info-key"
  }, React.createElement(FormattedMessage, commonMessages.status), ": "), React.createElement(StatusColor, {
    status: status
  })), React.createElement("div", {
    className: "offer-detail-page__list"
  }), React.createElement(OfferInformationList, {
    offerMetaItems: offer
  })), React.createElement("div", {
    className: "offer-detail-page__products-title"
  }, "Products"), products && products.length > 0 ? products === null || products === void 0 ? void 0 : products.map(function (item, i) {
    return React.createElement(OfferShippedProductItem, {
      key: "product__".concat(i),
      shippedProduct: item
    });
  }) : "No product information available"));
}