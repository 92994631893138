import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Redirect } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import { useCheckoutStepFromPath, useCheckoutStepState } from "@hooks";
import { CHECKOUT_STEPS } from "@temp/core/config";

var CheckoutRouter = function CheckoutRouter(_ref) {
  var items = _ref.items,
      checkout = _ref.checkout,
      payment = _ref.payment,
      renderAddress = _ref.renderAddress,
      renderShipping = _ref.renderShipping,
      renderPayment = _ref.renderPayment,
      renderReview = _ref.renderReview;

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname;

  var step = useCheckoutStepState(items, checkout, payment);
  var stepFromPath = useCheckoutStepFromPath(pathname);

  var getStepLink = function getStepLink() {
    var _CHECKOUT_STEPS$find;

    return ((_CHECKOUT_STEPS$find = CHECKOUT_STEPS.find(function (stepObj) {
      return stepObj.step === step;
    })) === null || _CHECKOUT_STEPS$find === void 0 ? void 0 : _CHECKOUT_STEPS$find.link) || CHECKOUT_STEPS[0].link;
  };

  if (!stepFromPath || stepFromPath && step < stepFromPath) {
    return React.createElement(Redirect, {
      to: getStepLink()
    });
  }

  return React.createElement(Switch, null, React.createElement(Route, {
    path: CHECKOUT_STEPS[0].link,
    render: renderAddress
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[1].link,
    render: renderShipping
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[2].link,
    render: renderPayment
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[3].link,
    render: renderReview
  }), React.createElement(Route, {
    render: function render(props) {
      return React.createElement(Redirect, _extends({}, props, {
        to: getStepLink()
      }));
    }
  }));
};

export { CheckoutRouter };