import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
/**
 * Cart header to use with conjunction of cart rows.
 */

var CartHeader = function CartHeader() {
  return React.createElement(S.Wrapper, null, React.createElement(S.Column, null, React.createElement(FormattedMessage, commonMessages.products)), React.createElement(S.Column, null, React.createElement(FormattedMessage, commonMessages.price)), React.createElement(S.Column, null, React.createElement(FormattedMessage, commonMessages.quantity)), React.createElement(S.Column, null, React.createElement(FormattedMessage, commonMessages.totalPrice)));
};

export { CartHeader };