import * as React from "react";
import "./scss/index.scss";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import { Button } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import CatalogSearch from "@temp/components/HybrITComponents/CatalogCustomHeader/CatalogSearch/CatalogSearch";
import ProjectListItem from "../../components/HybrITComponents/ProjectListItem";
import { Breadcrumbs } from "../../components";
import Loader from "../../components/Loader/index";
var breadcrumbs = [{
  link: ["/project"].join(""),
  value: "Projects"
}];

var Page = function Page(_ref) {
  var projects = _ref.projects,
      search = _ref.search,
      setSearch = _ref.setSearch,
      onLoadMore = _ref.onLoadMore,
      hasNextPage = _ref.hasNextPage,
      displayLoader = _ref.displayLoader;
  return React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "project-page"
  }, React.createElement("div", {
    className: "layout"
  }, !displayLoader ? React.createElement("div", {
    className: "items"
  }, projects && projects.length > 0 ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "search-section"
  }, React.createElement("div", {
    className: "search-section__field"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch
  }))), projects.map(function (project) {
    return React.createElement("span", {
      key: project.node.mainserviceobjectId
    }, React.createElement(ProjectListItem, {
      project: project.node
    }));
  })) : React.createElement(VisconNotFound, {
    message: "No projects were found in your account.",
    headerMsg: "No Projects Found"
  }), hasNextPage && React.createElement("div", {
    className: "projects-button__container"
  }, React.createElement(Button, {
    testingContext: "loadMoreProjectsButton",
    className: "button cart-button navigation",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "views.Project.Page.1138265409",
    defaultMessage: "Load more"
  })))) : React.createElement(React.Fragment, null, React.createElement("div", {
    className: "search-section"
  }, React.createElement("div", {
    className: "search-section__field"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch
  }))), React.createElement(Loader, null)))));
};

export default Page;