import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { MenuDropdown, Offline, Online, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import cartImg from "../../images/cart.svg";
import userImg from "../../images/user.svg";
import searchImg from "../../images/search.svg";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
export var RenderMainMenuRight = function RenderMainMenuRight(props) {
  var cartItemsQuantity = props.items && props.items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0;
  return React.createElement(React.Fragment, null, React.createElement("ul", null, props.user ? React.createElement("li", {
    "data-test": "menuSearchOverlayLink",
    className: "main-menu__search",
    onClick: function onClick() {
      return props.overlayContext.show(OverlayType.search, OverlayTheme.right);
    }
  }, React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.search));
    }
  }), React.createElement(ReactSVG, {
    path: searchImg
  })) : null, React.createElement(Online, null, props.user && React.createElement(MenuDropdown, {
    head: React.createElement("li", {
      className: "main-menu__icon main-menu__user--active"
    }, React.createElement(ReactSVG, {
      path: userImg
    })),
    content: React.createElement("ul", {
      className: "main-menu__dropdown"
    }, React.createElement("li", {
      "data-test": "desktopMenuMyAccountLink"
    }, React.createElement(Link, {
      to: appPaths.accountUrl
    }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
      "data-test": "desktopMenuAddressBookLink"
    }, React.createElement(Link, {
      to: appPaths.addressBookUrl
    }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
      onClick: props.handleSignOut,
      "data-test": "desktopMenuLogoutLink"
    }, React.createElement(FormattedMessage, commonMessages.logOut)))
  }), props.user && React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      props.overlayContext.show(OverlayType.cart, OverlayTheme.right);
    }
  }, React.createElement(ReactSVG, {
    path: cartImg
  }), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "main-menu__cart__quantity"
  }, cartItemsQuantity.toFixed(2)) : null)), React.createElement(Offline, null, React.createElement("li", {
    className: "main-menu__offline"
  }, React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("span", null, "Offline");
    }
  })))));
};