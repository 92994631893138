import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Cart footer to use with conjunction of cart rows
 */
var CartFooter = function CartFooter(_ref) {
  var subtotalPrice = _ref.subtotalPrice,
      shippingPrice = _ref.shippingPrice,
      discountPrice = _ref.discountPrice,
      totalPrice = _ref.totalPrice;
  return React.createElement(S.Wrapper, {
    showShipping: !!shippingPrice,
    showDiscount: !!discountPrice
  }, React.createElement(S.SubtotalText, null, React.createElement(FormattedMessage, commonMessages.subtotal)), React.createElement(S.SubtotalPrice, null, subtotalPrice), shippingPrice && React.createElement(React.Fragment, null, React.createElement(S.ShippingText, null, React.createElement(FormattedMessage, commonMessages.shipping)), React.createElement(S.ShippingPrice, null, shippingPrice)), discountPrice && React.createElement(React.Fragment, null, React.createElement(S.DiscountText, null, React.createElement(FormattedMessage, commonMessages.promoCode)), React.createElement(S.DiscountPrice, null, discountPrice)), React.createElement(S.TotalText, null, React.createElement(FormattedMessage, commonMessages.total)), React.createElement(S.TotalPrice, null, totalPrice));
};

export { CartFooter };