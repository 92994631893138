import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import { OrderList } from "../../components/HybrITComponents/OrderList";
import CatalogSearch from "../../components/HybrITComponents/CatalogCustomHeader/CatalogSearch/CatalogSearch";
import { Breadcrumbs } from "../../components";
import * as S from "./styles";
var ITEMS_PER_PAGE = 200;
var breadcrumbs = [{
  link: ["/order-history"].join(""),
  value: "Order history"
}];

var handleVisconNotFound = function handleVisconNotFound(data, d) {
  return data === null || data === undefined || data.length < 1 ? React.createElement(VisconNotFound, {
    message: "Sorry, the orders cannot not be displayed at the moment",
    messageTwo: "Please try again at a later moment.",
    headerMsg: "Orders cannot be displayed",
    buttonText: "Back to home"
  }) : false;
};

export default function Page(_ref) {
  var history = _ref.history,
      orders = _ref.orders,
      search = _ref.search,
      setSearch = _ref.setSearch;

  var _React$useState = React.useState(ITEMS_PER_PAGE),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      orderCounter = _React$useState2[0],
      setOrderCounter = _React$useState2[1];

  var loadmoreOrders = function loadmoreOrders() {
    setOrderCounter(orderCounter + ITEMS_PER_PAGE);
  };

  return React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "orders-page"
  }, handleVisconNotFound(orders) || React.createElement(React.Fragment, null, React.createElement("div", {
    className: "orders-page__search-field"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch,
    descriptionPlaceHolder: "Order Number"
  })), React.createElement("div", {
    className: "orders-page__orders-list"
  }, React.createElement(OrderList, {
    history: history,
    orders: orders,
    orderCounter: orderCounter
  }))), React.createElement(S.Wrapper, null, (orders === null || orders === void 0 ? void 0 : orders.length) > orderCounter ? React.createElement(Button, {
    className: "button cart-button navigation",
    testingContext: "loadMoreOrdersButton",
    onClick: function onClick() {
      loadmoreOrders();
    }
  }, React.createElement(FormattedMessage, {
    id: "views.OrderOverview.Page.1138265409",
    defaultMessage: "Load more"
  })) : null)));
}