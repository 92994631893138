import "./scss/index.scss";
import React from "react";
import { Button } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import { ListViewCollectionItem } from ".";
export var ListViewCatalog = function ListViewCatalog(_ref) {
  var collectionProducts = _ref.collectionProducts,
      canLoadMore = _ref.canLoadMore,
      onLoadMore = _ref.onLoadMore;
  return React.createElement(React.Fragment, null, collectionProducts && collectionProducts.edges.filter(function (edge) {
    return edge.node.product.isPublished === true;
  }).map(function (edge) {
    return React.createElement(ListViewCollectionItem, {
      product: edge.node.product,
      collectionProduct: edge,
      key: edge.node.product.id
    });
  }), canLoadMore && React.createElement("div", {
    className: "button__container"
  }, React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    className: "button cart-button navigation",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.ListView.ListViewCollectionCatalog.1138265409",
    defaultMessage: "Load more"
  }))));
};
export default ListViewCatalog;