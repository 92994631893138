import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Projects($search: String, $after: String) {\n    projects(first: 100, after: $after, filter: { search: $search }) {\n      totalCount\n      edges {\n        node {\n          name\n          slug\n          buildDate\n          description\n          orderCustomerName\n          mainserviceobjectId\n          relationId\n          metadata {\n            key\n            value\n          }\n          collections(first: 100) {\n            totalCount\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var projectsQuery = gql(_templateObject());
export var TypedProjectsQuery = TypedQuery(projectsQuery);