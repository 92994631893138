import _extends from "@babel/runtime/helpers/extends";
import classNames from "classnames";
import * as React from "react";

var renderNoOptions = function renderNoOptions() {
  return React.createElement("p", {
    className: "select__option select__option--disabled",
    key: "no-option"
  }, "No Options");
};

var getRef = function getRef(isSelected, ref) {
  return isSelected && {
    ref: ref
  };
};

var SelectOptionsList = React.forwardRef(function (_ref, ref) {
  var activeOption = _ref.activeOption,
      options = _ref.options,
      onChange = _ref.onChange,
      setOpen = _ref.setOpen,
      updateOptions = _ref.updateOptions;
  return React.createElement(React.Fragment, null, options.length ? options.map(function (_ref2) {
    var label = _ref2.label,
        value = _ref2.value;
    var isSelected = activeOption.value === value;
    return React.createElement("p", _extends({}, getRef(isSelected, ref), {
      className: classNames("select__option", {
        "select__option--selected": isSelected
      }),
      key: value,
      onClick: function onClick() {
        updateOptions({
          label: label,
          value: value
        }, onChange);
        setOpen(false);
      }
    }), label);
  }) : renderNoOptions());
});
export default SelectOptionsList;