import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React, { forwardRef, useImperativeHandle } from "react";
import { useIntl } from "react-intl";
import { CheckoutPayment } from "@components/organisms";
import { commonMessages } from "@temp/intl";

var CheckoutPaymentSubpageWithRef = function CheckoutPaymentSubpageWithRef(_ref, ref) {
  var paymentGatewayFormRef = _ref.paymentGatewayFormRef,
      changeSubmitProgress = _ref.changeSubmitProgress,
      onSubmitSuccess = _ref.onSubmitSuccess,
      onPaymentGatewayError = _ref.onPaymentGatewayError,
      props = _objectWithoutProperties(_ref, ["paymentGatewayFormRef", "changeSubmitProgress", "onSubmitSuccess", "onPaymentGatewayError"]);

  var intl = useIntl();
  useImperativeHandle(ref, function () {
    return {
      submitPayment: function submitPayment() {
        if (paymentGatewayFormRef.current) {
          paymentGatewayFormRef.current.dispatchEvent(new Event("submit", {
            cancelable: true
          }));
        } else {
          changeSubmitProgress(false);
          onPaymentGatewayError([{
            message: intl.formatMessage(commonMessages.choosePaymentMethod)
          }]);
        }
      }
    };
  });
  return React.createElement(CheckoutPayment, props);
};

var CheckoutPaymentSubpage = forwardRef(CheckoutPaymentSubpageWithRef);
export { CheckoutPaymentSubpage };