import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { OfflinePlaceholder } from "@temp/components";
import { useQueryParam, StringParam } from "use-query-params";
import { maybe } from "@utils/tsUtils";
import { TypedProjectsQuery } from "./queries";
import NetworkStatus from "../../components/NetworkStatus";
import Page from "./Page";

var View = function View() {
  var _useQueryParam = useQueryParam("search", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      search = _useQueryParam2[0],
      setSearch = _useQueryParam2[1];

  var variables = {
    search: search || null
  };
  return React.createElement("div", null, React.createElement(TypedProjectsQuery, {
    loaderFull: true,
    errorPolicy: "all",
    variables: variables
  }, function (projectsData) {
    return React.createElement(NetworkStatus, null, function (isOnline) {
      if (projectsData) {
        var uniqueProjectItems = _toConsumableArray(new Set(projectsData.data.projects.edges));

        var handleLoadMore = function handleLoadMore() {
          return projectsData.loadMore(function (prev, next) {
            return _objectSpread({}, prev, {
              projects: _objectSpread({}, prev.projects, {
                edges: [].concat(_toConsumableArray(prev.projects.edges), _toConsumableArray(next.projects.edges)),
                pageInfo: next.projects.pageInfo
              })
            });
          }, {
            after: projectsData.data.projects.pageInfo.endCursor
          });
        };

        if (!isOnline) {
          return React.createElement(OfflinePlaceholder, null);
        }

        return React.createElement(Page, {
          onLoadMore: handleLoadMore,
          hasNextPage: maybe(function () {
            return projectsData.data.projects.pageInfo.hasNextPage;
          }, false),
          projects: maybe(function () {
            return uniqueProjectItems;
          }, null),
          setSearch: setSearch,
          search: search,
          displayLoader: projectsData.loading
        });
      }
    });
  }));
};

export default View;