import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";

var Empty = function Empty(_ref) {
  var overlayHide = _ref.overlayHide;
  return React.createElement("div", {
    className: "cart__empty"
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.3330213154",
    defaultMessage: "Your cart is empty"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1102423545",
    defaultMessage: "You haven\u2019t added anything to your cart."
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, React.createElement(Button, {
    className: "button cart-button navigation",
    testingContext: "emptyCartHideOverlayButton",
    onClick: overlayHide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.3640052215",
    defaultMessage: "Continue"
  }))));
};

export default Empty;