import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var OfferTile = styled.label(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked === "offer" && "border: 2px solid #21125E;";
});
export var OrderTile = styled.label(_templateObject3(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked === "order" && "border: 2px solid #21125E;";
});
export var Tile = styled.label(_templateObject4(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked === "other" && "border: 2px solid #21125E;";
});