import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import granuPlate from "../../images/Auto-Seeder-Granuplate.png";
import blockOmatic from "../../images/Block-O-Matic-B-600.png";
import irrisprayFixed from "../../images/Irrispray-Fixed-YPN.png";
import fixoMatVision from "../../images/Pic-O-Mat-Vision.png";
import soilCrane from "../../images/Soil-Crane.png";
import pickUpSystem from "../../images/Space-O-Mat-Pick-Up-System.png";
import internalTransportSystem from "../../images/XY-System-Internal-Transport-System.png";
export var visconImages = [fixoMatVision, internalTransportSystem, granuPlate, pickUpSystem, blockOmatic, soilCrane, irrisprayFixed];
export var mappedBackgrounds = visconImages.map(function (img) {
  return {
    backgroundImage: "url(".concat(img, ")"),
    WebkitTransition: "background-image 1s",
    transition: "background-image 1s"
  };
});
export var PreRenderImages = function PreRenderImages(_ref) {
  var val = _ref.val;
  var children = ".";
  var defaultProps = {
    maxHeight: "1px",
    maxWidth: "1px",
    zIndex: -1,
    color: "#009ee3",
    MozColor: "#009ee3",
    visibility: val
  };
  return React.createElement("div", null, React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(fixoMatVision, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(internalTransportSystem, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(granuPlate, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(pickUpSystem, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(blockOmatic, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(soilCrane, ")")
    }, defaultProps)
  }, children), React.createElement("div", {
    style: _objectSpread({
      backgroundImage: "url(".concat(irrisprayFixed, ")")
    }, defaultProps)
  }, children));
};