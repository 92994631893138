import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Radio } from "@components/atoms";
import { Money } from "@components/containers";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Shipping method selector used in checkout.
 */
var CheckoutShipping = function CheckoutShipping(_ref) {
  var shippingMethods = _ref.shippingMethods,
      selectedShippingMethodId = _ref.selectedShippingMethodId,
      selectShippingMethod = _ref.selectShippingMethod,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef;
  return React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingMethod)), React.createElement(Formik, {
    initialValues: {
      shippingMethod: selectedShippingMethodId
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (selectShippingMethod && values.shippingMethod) {
        selectShippingMethod(values.shippingMethod);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(S.ShippingMethodForm, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, shippingMethods.map(function (_ref4, index) {
      var id = _ref4.id,
          name = _ref4.name,
          price = _ref4.price;
      var checked = !!values.shippingMethod && values.shippingMethod === id;
      return React.createElement(S.Tile, {
        checked: checked,
        key: id,
        "data-test": "shippingMethodTile",
        "data-test-id": id
      }, React.createElement(Radio, {
        name: "shippingMethod",
        value: id,
        checked: checked,
        customLabel: true,
        onChange: function onChange() {
          return setFieldValue("shippingMethod", id);
        }
      }, React.createElement("span", {
        "data-test": "checkoutShippingMethodOptionName"
      }, name), React.createElement(S.Price, null, " ", "| +", React.createElement(Money, {
        "data-test": "checkoutShippingMethodOptionPrice",
        money: price
      }))));
    }), React.createElement(ErrorMessage, {
      errors: errors
    }));
  }));
};

export { CheckoutShipping };