import _objectSpread from "@babel/runtime/helpers/objectSpread";

var _this = this;

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TaxedMoney } from "@components/containers";
import { checkoutMessages, translatePaymentStatus, translateOrderStatus } from "@temp/intl";
import { DropdownMenu, IconButton } from "@components/atoms";
import { AddressSummary, CartTable, NotFound } from "../../../components";
import { orderHistoryUrl } from "../../../app/routes";

var extractOrderLines = function extractOrderLines(lines) {
  return lines.map(function (line) {
    return _objectSpread({
      quantity: line.quantity,
      totalPrice: _objectSpread({}, line.unitPrice, {
        currency: line.unitPrice.currency,
        gross: _objectSpread({
          amount: line.quantity * line.unitPrice.gross.amount
        }, line.unitPrice.gross),
        net: _objectSpread({
          amount: line.quantity * line.unitPrice.net.amount
        }, line.unitPrice.net)
      })
    }, line.variant, {
      name: line.productName
    });
  }).sort(function (a, b) {
    return b.id.toLowerCase().localeCompare(a.id.toLowerCase());
  });
};

var Page = function Page(_ref) {
  var _order$invoices;

  var guest = _ref.guest,
      order = _ref.order,
      downloadInvoice = _ref.downloadInvoice;
  var intl = useIntl();
  return order ? React.createElement(React.Fragment, null, !guest && React.createElement(Link, {
    className: "order-details__link",
    to: orderHistoryUrl
  }, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.624688748",
    defaultMessage: "Go back to Order History"
  })), React.createElement("div", {
    className: "order-details__header"
  }, React.createElement("div", null, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.1857586798",
    defaultMessage: "Your order no.: {orderNum}",
    values: {
      orderNum: order.number
    }
  })), React.createElement("p", {
    className: "order-details__status"
  }, translatePaymentStatus(order.paymentStatusDisplay, intl), " /", " ", translateOrderStatus(order.statusDisplay, intl))), "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0 && React.createElement("div", {
    className: "order-details__header-menu"
  }, React.createElement(DropdownMenu, {
    type: "clickable",
    header: React.createElement(IconButton, {
      testingContext: "expandButton",
      name: "expand",
      size: 28
    }),
    items: [{
      onClick: downloadInvoice,
      content: React.createElement("span", null, React.createElement(FormattedMessage, {
        id: "userAccount.views.OrderDetails.Page.2670827143",
        defaultMessage: "Download invoice",
        description: "action in popup menu in order view"
      }))
    }]
  }))), React.createElement(CartTable, {
    lines: extractOrderLines(order.lines),
    totalCost: React.createElement(TaxedMoney, {
      taxedMoney: order.total
    }),
    deliveryCost: React.createElement(TaxedMoney, {
      taxedMoney: order.shippingPrice
    }),
    subtotal: React.createElement(TaxedMoney, {
      taxedMoney: order.subtotal
    })
  }), React.createElement("div", {
    className: "order-details__summary"
  }, React.createElement("div", null, React.createElement("h4", null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(AddressSummary, {
    address: order.shippingAddress,
    email: order.userEmail,
    paragraphRef: _this.shippingAddressRef
  })))) : React.createElement(NotFound, null);
};

export default Page;