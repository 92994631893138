import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query Products(\n    $after: String\n    $sortBy: ProductOrder\n    $pageSize: Int\n    $search: String\n    $attributes: [AttributeInput]\n    $priceLte: Float\n    $priceGte: Float\n    $categories: [ID]\n  ) {\n    products(\n      first: $pageSize\n      after: $after\n      sortBy: $sortBy\n      filter: {\n        search: $search\n        attributes: $attributes\n        categories: $categories\n        minimalPrice: { gte: $priceGte, lte: $priceLte }\n      }\n    ) {\n      totalCount\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n          id\n          name\n          slug\n          description\n          descriptionJson\n          thumbnail {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: 510) {\n            url\n          }\n          category {\n            id\n            name\n          }\n          metadata {\n            key\n            value\n          }\n          variants {\n            id\n            stocks {\n              quantity\n            }\n            price {\n              currency\n              amount\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Category($id: ID!) {\n    category(id: $id) {\n      id\n      slug\n      name\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productPricingFragment, basicProductFragment } from "../Product/queries";
export var categoryDataQuery = gql(_templateObject());
export var productsDataQuery = gql(_templateObject2(), productPricingFragment, basicProductFragment);
export var TypedProductsDataQuery = TypedQuery(productsDataQuery);
export var TypedCategoryDataQuery = TypedQuery(categoryDataQuery);