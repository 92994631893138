import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useEffect } from "react";
import ReactSVG from "react-svg";
import searchImg from "../../../../images/search.svg";

var CatalogSearch = function CatalogSearch(_ref) {
  var setSearch = _ref.setSearch,
      search = _ref.search,
      descriptionPlaceHolder = _ref.descriptionPlaceHolder;

  var _React$useState = React.useState(search || ""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      searchInput = _React$useState2[0],
      setSearchInput = _React$useState2[1];

  var handleSubmit = function handleSubmit(e) {
    e.preventDefault();
    setSearch(searchInput);
  };

  useEffect(function () {
    setSearchInput(search || "");
  }, [search]);
  return React.createElement(React.Fragment, null, React.createElement("form", {
    onSubmit: function onSubmit(e) {
      return handleSubmit(e);
    },
    className: "catalog-search__form"
  }, React.createElement("input", {
    type: "text",
    value: searchInput,
    onChange: function onChange(e) {
      return setSearchInput(e.target.value);
    },
    placeholder: "".concat(descriptionPlaceHolder || "search..")
  }), React.createElement("button", {
    type: "submit",
    className: "catalog-search__form__button"
  }, React.createElement(ReactSVG, {
    path: searchImg
  }))));
};

export default CatalogSearch;