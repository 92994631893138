import "./scss/index.scss";
import React from "react";
import { Button } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import ListViewItem from "./ListViewItem/ListViewItem";
export var ListViewCatalog = function ListViewCatalog(_ref) {
  var products = _ref.products,
      canLoadMore = _ref.canLoadMore,
      onLoadMore = _ref.onLoadMore;
  return React.createElement(React.Fragment, null, products.map(function (product) {
    return React.createElement(ListViewItem, {
      product: product,
      key: product.id
    });
  }), canLoadMore && React.createElement("div", {
    className: "button__container"
  }, React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    className: "button cart-button navigation",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.ListView.ListViewCatalog.1138265409",
    defaultMessage: "Load more"
  }))));
};
export default ListViewCatalog;