import { Money } from "@components/containers/Money";
import React from "react";
import "./scss/index.scss";
export default function OfferInformationList(_ref) {
  var _metaItems$shippingAd, _metaItems$shippingAd2, _metaItems$shippingAd3, _metaItems$shippingAd4;

  var offerMetaItems = _ref.offerMetaItems;
  var metaItems = offerMetaItems;

  var formatDate = function formatDate(date) {
    return date ? new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }) : "None";
  };

  var orNone = function orNone(val) {
    return typeof val === "number" && val > -1 ? val : typeof val === "string" && val !== "" ? val : "None";
  };

  var offerInformationItems = [{
    key: "Offer number",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : metaItems.offerNumber)
  }, {
    key: "Offer date",
    value: orNone(formatDate(metaItems === null || metaItems === void 0 ? void 0 : metaItems.offerDate))
  }, {
    key: "Incoterm",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : metaItems.incoTerm)
  }];
  var shippingInformationItems = [{
    key: "Address",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : (_metaItems$shippingAd = metaItems.shippingAddress) === null || _metaItems$shippingAd === void 0 ? void 0 : _metaItems$shippingAd.street)
  }, {
    key: "City",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : (_metaItems$shippingAd2 = metaItems.shippingAddress) === null || _metaItems$shippingAd2 === void 0 ? void 0 : _metaItems$shippingAd2.city)
  }, {
    key: "Country",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : (_metaItems$shippingAd3 = metaItems.shippingAddress) === null || _metaItems$shippingAd3 === void 0 ? void 0 : _metaItems$shippingAd3.country)
  }, {
    key: "Zip code",
    value: orNone(metaItems === null || metaItems === void 0 ? void 0 : (_metaItems$shippingAd4 = metaItems.shippingAddress) === null || _metaItems$shippingAd4 === void 0 ? void 0 : _metaItems$shippingAd4.zipCode)
  }];

  var formatValue = function formatValue(val) {
    return React.createElement(Money, {
      money: {
        amount: orNone(val),
        currency: "EUR"
      }
    });
  };

  var totalsItems = [{
    key: "VAT",
    value: formatValue(metaItems === null || metaItems === void 0 ? void 0 : metaItems.totalVat)
  }, {
    key: "Total discount",
    value: formatValue(metaItems === null || metaItems === void 0 ? void 0 : metaItems.totalDiscount)
  }, {
    key: React.createElement("div", {
      className: "offer-info-meta-item__grand-total"
    }, "Grand total"),
    value: formatValue(metaItems === null || metaItems === void 0 ? void 0 : metaItems.totalValue)
  }];

  var renderItem = function renderItem(item, i) {
    return React.createElement("div", {
      key: "".concat(item, "_").concat(i)
    }, React.createElement("div", {
      className: "offer-info-meta-item"
    }, React.createElement("div", {
      className: "offer-info-meta-item__key"
    }, item.key), React.createElement("div", {
      className: "offer-info-meta-item__value"
    }, item.value)));
  };

  var mapOfferInformationItems = offerInformationItems.map(function (item, i) {
    return renderItem(item, i);
  });
  var mapShippingInformationItems = shippingInformationItems.map(function (item, i) {
    return renderItem(item, i);
  });
  var mapTotals = totalsItems.map(function (item, i) {
    return renderItem(item, i);
  });
  return React.createElement("div", {
    className: "offer-info-container"
  }, React.createElement("div", {
    className: "section-one-title"
  }, "Offer Information"), React.createElement("div", {
    className: "section-one-content"
  }, mapOfferInformationItems), React.createElement("div", {
    className: "section-two-title"
  }, "Shipping Information"), React.createElement("div", {
    className: "section-two-content"
  }, mapShippingInformationItems), React.createElement("div", {
    className: "section-three-title"
  }, "Totals"), React.createElement("div", {
    className: "section-three-content"
  }, mapTotals));
}