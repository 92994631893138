import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../core/config";
import Button from "../../Button";

var VisconNotFound = function VisconNotFound(_ref) {
  var headerMsg = _ref.headerMsg,
      message = _ref.message,
      messageTwo = _ref.messageTwo,
      _ref$noButton = _ref.noButton,
      noButton = _ref$noButton === void 0 ? false : _ref$noButton,
      _ref$noTitle = _ref.noTitle,
      noTitle = _ref$noTitle === void 0 ? false : _ref$noTitle,
      _ref$buttonText = _ref.buttonText,
      buttonText = _ref$buttonText === void 0 ? null : _ref$buttonText,
      _ref$buttonUrl = _ref.buttonUrl,
      buttonUrl = _ref$buttonUrl === void 0 ? null : _ref$buttonUrl;
  return React.createElement("div", {
    className: "viscon-not-found-page"
  }, !noTitle && React.createElement(React.Fragment, null, React.createElement("h2", {
    className: "viscon-not-found-page__header"
  }, headerMsg), React.createElement("div", {
    className: "viscon-not-found-page__ruler"
  })), React.createElement("div", {
    className: "viscon-not-found-page__message"
  }, React.createElement("p", null, message), messageTwo && React.createElement("p", null, messageTwo)), noButton ? null : React.createElement("div", {
    className: "viscon-not-found-page__button"
  }, React.createElement(Link, {
    to: buttonUrl || BASE_URL
  }, React.createElement(Button, {
    testingContext: "404pageGotoHomeButton",
    secondary: true
  }, buttonText || React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.VisconNotFound.index.1723676032",
    defaultMessage: "Back to home"
  })))));
};

export default VisconNotFound;