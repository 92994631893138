import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
export var QuantityTextField = function QuantityTextField(_ref) {
  var quantity = _ref.quantity,
      onQuantityChange = _ref.onQuantityChange;
  var intl = useIntl();

  var _React$useState = React.useState(quantity.toString()),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      tempQuantity = _React$useState2[0],
      setTempQuantity = _React$useState2[1];

  var handleQuantityInput = function handleQuantityInput(e) {
    setTempQuantity(e.target.value.replace(",", "."));
  };

  var handleQuantityBlur = function handleQuantityBlur(e) {
    onQuantityChange(parseFloat(tempQuantity) || 0);
  };

  var handleQuantityFocus = function handleQuantityFocus(e) {
    if (quantity !== 0) {
      setTempQuantity(quantity.toString());
    }
  };

  return React.createElement(TextField, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.quantity),
    value: tempQuantity,
    onChange: handleQuantityInput,
    onBlur: handleQuantityBlur,
    onFocus: handleQuantityFocus
  });
};