import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { Container } from "../Container";
/**
 * Template for empty cart page.
 */

var CartEmpty = function CartEmpty(_ref) {
  var button = _ref.button;
  return React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement(S.TitleFirstLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3578601618",
    defaultMessage: "Your Cart"
  })), React.createElement(S.TitleSecondLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.2488840083",
    defaultMessage: "looks empty"
  })), React.createElement(S.HR, null), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.2896212356",
    defaultMessage: "Maybe you haven\u2019t made your choices yet"
  })), React.createElement(S.ContinueButton, null, button)));
};

export { CartEmpty };