import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query HomePageMachines($search: String, $after: String) {\n    collections(after: $after, first: 4, filter: { search: $search }) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          relationId\n          isPublished\n          backgroundImage {\n            url\n          }\n          publicationDate\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query ProductsList {\n    shop {\n      description\n      name\n      homepageCollection {\n        id\n        backgroundImage {\n          url\n        }\n        name\n      }\n    }\n    categories(level: 0, first: 4) {\n      edges {\n        node {\n          id\n          name\n          backgroundImage {\n            url\n          }\n        }\n      }\n    }\n    products(first: 4) {\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n          id\n          name\n          slug\n          thumbnail {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: 510) {\n            url\n          }\n          category {\n            id\n            name\n          }\n          metadata {\n            key\n            value\n          }\n          variants {\n            id\n            stocks {\n              quantity\n            }\n            price {\n              currency\n              amount\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productPricingFragment, basicProductFragment } from "../Product/queries";
export var homePageQuery = gql(_templateObject(), productPricingFragment, basicProductFragment);
export var homePageMachinesQuery = gql(_templateObject2());
export var TypedHomePageQuery = TypedQuery(homePageQuery);
export var TypedHomePageMachinesQuery = TypedQuery(homePageMachinesQuery);