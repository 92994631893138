import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { MenuDropdown, Online, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import NavDropdown from "./NavDropdown";
import { TypedMainMenuQuery } from "./queries";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import userImg from "../../images/user.svg";
import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
import defaultPages from "./defaultPages";
export var RenderLinkMenu = function RenderLinkMenu(props) {
  return React.createElement(TypedMainMenuQuery, {
    renderOnError: true,
    displayLoader: false
  }, function (_ref) {
    var data = _ref.data;
    var itemsWithDefaults = defaultPages.concat(data.shop.navigation.main.items);
    var items = maybe(function () {
      return itemsWithDefaults;
    }, []);
    var offersLinkObject = items.find(function (item) {
      return item.name === "Offers";
    });

    var updatedOffersLink = _objectSpread({}, offersLinkObject, {
      name: "".concat(offersLinkObject.name, " / Orders")
    });

    var updatedLinkArray = [].concat(_toConsumableArray(items.filter(function (item) {
      return item.name !== "Offers";
    })), [updatedOffersLink]);
    return React.createElement("ul", null, React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("li", {
          "data-test": "toggleSideMenuLink",
          className: "main-menu__hamburger",
          onClick: function onClick() {
            return props.overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
              data: updatedLinkArray
            });
          }
        }, React.createElement(ReactSVG, {
          path: hamburgerImg,
          className: "main-menu__hamburger--icon"
        }), React.createElement(ReactSVG, {
          path: hamburgerHoverImg,
          className: "main-menu__hamburger--hover"
        }));
      }
    }), React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return updatedLinkArray.map(function (item) {
          return React.createElement("li", {
            "data-test": "mainMenuItem",
            className: "main-menu__item",
            key: item.id
          }, React.createElement(NavDropdown, _extends({
            overlay: props.overlayContext
          }, item)));
        });
      }
    }), React.createElement(Online, null, React.createElement(Media, {
      query: {
        maxWidth: smallScreen
      },
      render: function render() {
        return React.createElement(React.Fragment, null, props.user ? React.createElement(MenuDropdown, {
          suffixClass: "__rightdown",
          head: React.createElement("li", {
            className: "main-menu__icon main-menu__user--active"
          }, React.createElement(ReactSVG, {
            path: userImg
          })),
          content: React.createElement("ul", {
            className: "main-menu__dropdown"
          }, React.createElement("li", {
            "data-test": "mobileMenuMyAccountLink"
          }, React.createElement(Link, {
            to: appPaths.accountUrl
          }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
            "data-test": "mobileMenuOrderHistoryLink"
          }, React.createElement(Link, {
            to: appPaths.orderHistoryUrl
          }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
            "data-test": "mobileMenuAddressBookLink"
          }, React.createElement(Link, {
            to: appPaths.addressBookUrl
          }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
            onClick: props.handleSignOut,
            "data-test": "mobileMenuLogoutLink"
          }, React.createElement(FormattedMessage, commonMessages.logOut)))
        }) : React.createElement("li", {
          "data-test": "mobileMenuLoginLink",
          className: "main-menu__icon",
          onClick: function onClick() {
            return props.overlayContext.show(OverlayType.login, OverlayTheme.left);
          }
        }, React.createElement(ReactSVG, {
          path: userImg
        })));
      }
    })));
  });
};