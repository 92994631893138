import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Payment options used in checkout.
 */
var CheckoutPayment = function CheckoutPayment(_ref) {
  _objectDestructuringEmpty(_ref);

  return React.createElement(S.Wrapper, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement(S.Divider, null)));
};

export { CheckoutPayment };