import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import ListViewCatalog from "@temp/components/HybrITComponents/ListView/ListViewCatalog";
import { FormattedMessage } from "react-intl";
import { MetaDataBlocks } from "@temp/components/HybrITComponents";
import { TypedDetailReplacementProductDetailsDataQuery, TypedDetailAlternativeProductDetailsDataQuery } from "./queries";
import { structuredData } from "../../core/SEO/Product/structuredData";
import { ProductGallery } from "../../@next/components/organisms";
import { slugify, getDBIdFromGraphqlId, generateProductWithCategoryUrl } from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
import { Breadcrumbs, ProductDescription } from "../../components";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: ["/product"].join(""),
    value: "Parts Catalog"
  }, {
    link: "/product/".concat(slugify(product.category.name), "/").concat(getDBIdFromGraphqlId(product.category.id, "Category"), "/"),
    value: product.category.name
  }, {
    link: "/product/".concat(slugify(product.category.name), "/").concat(getDBIdFromGraphqlId(product.category.id, "Category"), "/").concat(slugify(product.name), "/").concat(getDBIdFromGraphqlId(product.id, "Product"), "/"),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var _product$metadata$fin, _product$metadata$fin2;

  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var productGallery = React.useRef();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.filter(function (variant) {
        return variant.id === variantId;
      }).pop();

      if (variant.images.length > 0) {
        return variant.images;
      }

      return product.images;
    }

    return product.images;
  };

  var replacementProductSlug = product === null || product === void 0 ? void 0 : (_product$metadata$fin = product.metadata.find(function (obj) {
    return obj.key === "replacementProductSlug";
  })) === null || _product$metadata$fin === void 0 ? void 0 : _product$metadata$fin.value;
  var alternativeProductSlug = product === null || product === void 0 ? void 0 : (_product$metadata$fin2 = product.metadata.find(function (obj) {
    return obj.key === "alternativeProductSlug";
  })) === null || _product$metadata$fin2 === void 0 ? void 0 : _product$metadata$fin2.value;
  var unmarketable = product === null || product === void 0 ? void 0 : product.metadata.find(function (obj) {
    return obj.key === "unmarketable";
  });
  var isUnmarketable = unmarketable !== null && unmarketable !== undefined ? JSON.parse(unmarketable.value) : null;
  var noReplacement = product === null || product === void 0 ? void 0 : product.metadata.find(function (obj) {
    return obj.key === "noreplacement";
  });
  var hasNoReplacement = noReplacement !== null && noReplacement !== undefined ? JSON.parse(noReplacement.value) : null;
  var showReplacements = isUnmarketable && !hasNoReplacement;
  var isDisabled = isUnmarketable;
  var productDescription = React.createElement(ProductDescription, {
    items: items,
    productId: product.id,
    name: product.name,
    productVariants: product.variants,
    pricing: product.pricing,
    queryAttributes: queryAttributes,
    addToCart: add,
    setVariantId: setVariantId,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isDisabled: isDisabled
  });
  return React.createElement(TypedDetailReplacementProductDetailsDataQuery, {
    errorPolicy: "all",
    loaderFull: true,
    variables: {
      slug: replacementProductSlug || ""
    }
  }, function (replacementProductData) {
    var _replacementProductDa;

    var replacementProduct = replacementProductData === null || replacementProductData === void 0 ? void 0 : (_replacementProductDa = replacementProductData.data) === null || _replacementProductDa === void 0 ? void 0 : _replacementProductDa.product;
    return React.createElement(TypedDetailAlternativeProductDetailsDataQuery, {
      errorPolicy: "all",
      loaderFull: true,
      variables: {
        slug: alternativeProductSlug || ""
      }
    }, function (alternativeProductData) {
      var _alternativeProductDa;

      var alternativeProduct = alternativeProductData === null || alternativeProductData === void 0 ? void 0 : (_alternativeProductDa = alternativeProductData.data) === null || _alternativeProductDa === void 0 ? void 0 : _alternativeProductDa.product;
      return React.createElement("div", {
        className: "product-page"
      }, React.createElement("div", {
        className: "container"
      }, React.createElement(Breadcrumbs, {
        breadcrumbs: populateBreadcrumbs(product)
      })), React.createElement("div", {
        className: "container"
      }, React.createElement("div", {
        className: "product-page__product"
      }, React.createElement("script", {
        className: "structured-data-list",
        type: "application/ld+json"
      }, structuredData(product)), React.createElement(Media, {
        query: {
          maxWidth: mediumScreen
        }
      }, function (matches) {
        return matches ? React.createElement(React.Fragment, null, React.createElement(GalleryCarousel, {
          images: getImages()
        }), React.createElement("div", {
          className: "product-page__product__info"
        }, productDescription, isUnmarketable && React.createElement("div", {
          className: "product-page__product__metaData__subtitle__smallscreen"
        }, "This product is no longer available"), React.createElement("div", {
          className: "product-page__product__metaData"
        }, React.createElement(MetaDataBlocks, {
          metadata: product.metadata,
          stockQuantity: product.variants[0].stocks[0].quantity,
          category: product.category,
          slug: product.slug,
          description: product.description,
          showReplacements: showReplacements,
          replacementProductUrl: replacementProduct ? generateProductWithCategoryUrl(replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.name, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.name) : "",
          alternativeProductUrl: alternativeProduct ? generateProductWithCategoryUrl(alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.name, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.name) : ""
        })))) : React.createElement(React.Fragment, null, React.createElement("div", {
          className: "product-page__product__gallery",
          ref: productGallery
        }, React.createElement(ProductGallery, {
          images: getImages()
        })), React.createElement("div", {
          className: "product-page__product__info"
        }, React.createElement("div", {
          className: classNames("product-page__product__info--fixed")
        }, productDescription, isUnmarketable && React.createElement("div", {
          className: "product-page__product__metaData__subtitle"
        }, "This product is no longer available"), React.createElement("div", {
          className: "product-page__product__metaData"
        }, React.createElement(MetaDataBlocks, {
          metadata: product.metadata,
          stockQuantity: product.variants[0].stocks[0].quantity,
          category: product.category,
          slug: product.slug,
          description: product.description,
          showReplacements: showReplacements,
          replacementProductUrl: replacementProduct ? generateProductWithCategoryUrl(replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.name, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.name) : "",
          alternativeProductUrl: alternativeProduct ? generateProductWithCategoryUrl(alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.name, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.name) : ""
        })))));
      }))), React.createElement("div", {
        className: "product-page__other-products"
      }, React.createElement("div", {
        className: "container"
      }, React.createElement("h3", {
        className: "product-page__other-products__title"
      }, React.createElement(FormattedMessage, {
        id: "views.Product.Page.3212906417",
        defaultMessage: "Other products in this category"
      })), React.createElement(ListViewCatalog, {
        canLoadMore: false,
        products: product.category.products.edges.map(function (edge) {
          return edge.node;
        })
      }))));
    });
  });
};

export default Page;