import React from "react";
import ScrollToTop from "react-scroll-up";
import { AiOutlineArrowUp } from "react-icons/ai";
var showArrowAt = 160;
export default function BackToTop() {
  return React.createElement(ScrollToTop, {
    showUnder: showArrowAt
  }, React.createElement(AiOutlineArrowUp, {
    style: {
      height: "30px",
      width: "30px",
      border: "1px solid black",
      borderRadius: "3px",
      background: "#f6f6f6"
    }
  }));
}