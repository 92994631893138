import "./scss/index.scss";
import isEqual from "lodash/isEqual";
import * as React from "react";
import { Thumbnail } from "@components/molecules";
import { TaxedMoney } from "../../@next/components/containers";

var ProductListItem = function ProductListItem(_ref) {
  var product = _ref.product;
  var category = product.category;
  var price = product.pricing.priceRange.start;
  var priceUndiscounted = product.pricing.priceRangeUndiscounted.start;

  var getProductPrice = function getProductPrice() {
    if (isEqual(price, priceUndiscounted)) {
      return React.createElement(TaxedMoney, {
        taxedMoney: price
      });
    }

    return React.createElement(React.Fragment, null, React.createElement("span", {
      className: "product-list-item__undiscounted_price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: priceUndiscounted
    })), "\xA0\xA0\xA0\xA0", React.createElement(TaxedMoney, {
      taxedMoney: price
    }));
  };

  return React.createElement("div", {
    className: "product-list-item"
  }, React.createElement("div", {
    className: "product-list-item__image"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement("h4", {
    className: "product-list-item__title"
  }, product.name), React.createElement("p", {
    className: "product-list-item__category"
  }, category.name), React.createElement("p", {
    className: "product-list-item__price"
  }, getProductPrice()));
};

export default ProductListItem;