import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import ListViewCatalog from "@temp/components/HybrITComponents/ListView/ListViewCatalog";
import QRScanner from "@temp/components/HybrITComponents/QRScanner";
import { MachineList } from "@temp/components/HybrITComponents";
import OrderOfferTable from "@temp/components/HybrITComponents/OrderOfferTable/OrderOfferTable";
import { Button, Loader } from "../../@next/components/atoms";
import * as PreRender from "./PreRender";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
export var Page = function Page(_ref) {
  var products = _ref.products,
      shop = _ref.shop,
      machines = _ref.machines,
      orders = _ref.orders,
      offers = _ref.offers,
      hasNextPage = _ref.hasNextPage,
      loadingOffers = _ref.loadingOffers,
      loadingOrders = _ref.loadingOrders,
      displayLoader = _ref.displayLoader;

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      imageCounter = _React$useState2[0],
      setImageCounter = _React$useState2[1];

  var _React$useState3 = React.useState("visible"),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      preRender = _React$useState4[0],
      setPrerender = _React$useState4[1];

  React.useEffect(function () {
    var interval = setInterval(function () {
      setImageCounter(function (imageCounter) {
        return imageCounter + 1;
      });
    }, 7000);
    return function () {
      return clearInterval(interval);
    };
  }, []);
  React.useEffect(function () {
    var timer = setTimeout(function () {
      setPrerender("hidden");
    }, 1000);
    return function () {
      return clearTimeout(timer);
    };
  }, []);
  if (imageCounter > 6) setImageCounter(function (imageCounter) {
    return imageCounter - imageCounter;
  });

  var productsExist = function productsExist() {
    return products && products.edges && products.edges.length > 0;
  };

  var history = useHistory();
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement(PreRender.PreRenderImages, {
    val: preRender
  }), React.createElement("div", {
    className: "home-page__hero",
    style: PreRender.mappedBackgrounds[imageCounter]
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h2", {
    className: "home-page__hero__sub-title"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.675885278",
    defaultMessage: "Viscon Customer Portal"
  })))), React.createElement("div", {
    className: "home-page__hero-action"
  }, React.createElement("div", {
    className: "home-page__hero-buttons"
  }, React.createElement(QRScanner, {
    history: history
  })))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement(OrderOfferTable, {
    orders: orders,
    offers: offers,
    offersLoading: loadingOffers,
    ordersLoading: loadingOrders
  })), React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.548716751",
    defaultMessage: "MACHINES"
  }))), displayLoader ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, machines && machines.length > 0 ? React.createElement(MachineList, {
    machines: machines
  }) : React.createElement(VisconNotFound, {
    message: "No machines were found in your account.",
    headerMsg: "No Machines Found",
    noButton: true,
    noTitle: true
  })), hasNextPage && React.createElement("div", {
    className: "home-page__machine-link"
  }, React.createElement(Link, {
    to: "/machine"
  }, React.createElement(Button, {
    testingContext: "navigationButton",
    className: "button cart navigation"
  }, "Visit machines"))), React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1676103402",
    defaultMessage: "Parts"
  })), productsExist() && React.createElement(React.Fragment, null, React.createElement(ListViewCatalog, {
    products: products.edges.map(function (edge) {
      return edge.node;
    })
  }), React.createElement("div", {
    className: "home-page__go-to-parts-button"
  }, React.createElement(Link, {
    to: "/product"
  }, React.createElement(Button, {
    testingContext: "navigationButton",
    className: "button cart navigation"
  }, "Visit parts catalog")))))));
};
export default Page;