import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { TextField } from "../TextField";
import * as S from "./styles";
export var ResetPasswordForm = function ResetPasswordForm(_ref) {
  var handleBlur = _ref.handleBlur,
      handleChange = _ref.handleChange,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      tokenError = _ref.tokenError,
      passwordError = _ref.passwordError,
      errors = _ref.errors;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.4028609483",
    defaultMessage: "Reset your password"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.1865992141",
    defaultMessage: "Please provide new password"
  })), tokenError && React.createElement(S.GeneralError, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.2746979176",
    defaultMessage: "It seems that token for password reset is not valid anymore."
  })), React.createElement("form", {
    onSubmit: handleSubmit
  }, React.createElement(S.InputFields, null, React.createElement(TextField, {
    label: intl.formatMessage(commonMessages.password),
    name: "password",
    onBlur: handleBlur,
    onChange: handleChange,
    type: "password",
    value: values.password,
    errors: errors.password || passwordError ? [{
      field: "password",
      message: errors.password || passwordError
    }] : undefined
  }), React.createElement(TextField, {
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Retype password",
      "id": "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.2266763214"
    }, "id", "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.2266763214")),
    onBlur: handleBlur,
    name: "retypedPassword",
    onChange: handleChange,
    type: "password",
    value: values.retypedPassword,
    errors: errors.retypedPassword ? [{
      field: "retypedPassword",
      message: errors.retypedPassword
    }] : undefined
  })), React.createElement(Button, {
    testingContext: "submit",
    type: "submit",
    className: "button cart-button navigation",
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ResetPasswordForm.ResetPasswordForm.3951836694",
    defaultMessage: "SET NEW PASSWORD"
  }))));
};