import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { setAuthToken, useSetPassword } from "@saleor/sdk";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { StringParam, useQueryParams } from "use-query-params";
import { BASE_URL } from "@temp/core/config";
import { ResetPasswordForm } from "@components/molecules";
import * as S from "./styles";
var PasswordResetSchema = Yup.object().shape({
  password: Yup.string().min(2, "Password is to short!").required("This field is required"),
  retypedPassword: Yup.string().min(2, "Please retype password").required("This field is required").oneOf([Yup.ref("password")], "Retyped password does not match")
});
var initialData = {
  password: "",
  retypedPassword: ""
};
export var PasswordReset = function PasswordReset(_ref) {
  var history = _ref.history;

  var _useQueryParams = useQueryParams({
    email: StringParam,
    token: StringParam
  }),
      _useQueryParams2 = _slicedToArray(_useQueryParams, 1),
      query = _useQueryParams2[0];

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      tokenError = _React$useState2[0],
      setTokenError = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      passwordError = _React$useState4[0],
      setPasswordError = _React$useState4[1];

  var _useSetPassword = useSetPassword(),
      _useSetPassword2 = _slicedToArray(_useSetPassword, 2),
      setPassword = _useSetPassword2[0],
      _useSetPassword2$ = _useSetPassword2[1],
      data = _useSetPassword2$.data,
      graphqlErrors = _useSetPassword2$.error;

  React.useEffect(function () {
    if (data && data.setPassword && data.setPassword.token) {
      setAuthToken(data.setPassword.token);
      history.push(BASE_URL);
    }

    if (graphqlErrors && graphqlErrors.extraInfo && graphqlErrors.extraInfo.userInputErrors) {
      graphqlErrors.extraInfo.userInputErrors.forEach(function (error) {
        if (error.field === "token") setTokenError(true);else setTokenError(false);
        if (error.field === "password") setPasswordError(error.message);else setPasswordError("");
      });
    }
  }, [data, graphqlErrors]);
  var email = query.email,
      token = query.token;

  if (!email || !token) {
    history.push(BASE_URL);
  }

  var onSubmit = function onSubmit(values) {
    if (email && token && values.password) {
      setPassword({
        email: email,
        password: values.password,
        token: token
      });
    }
  };

  return React.createElement("div", {
    className: "reset-password-page"
  }, React.createElement(S.Wrapper, null, React.createElement(Formik, {
    initialValues: initialData,
    validationSchema: PasswordResetSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: false
  }, function (_ref2) {
    var handleChange = _ref2.handleChange,
        handleBlur = _ref2.handleBlur,
        values = _ref2.values,
        errors = _ref2.errors,
        handleSubmit = _ref2.handleSubmit;
    return React.createElement(ResetPasswordForm, {
      errors: errors,
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      passwordError: passwordError,
      tokenError: tokenError,
      values: values
    });
  })));
};