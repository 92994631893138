import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Projects($projectid: String) {\n    projects(first: 1, filter: { mainserviceobjectId: $projectid }) {\n      totalCount\n      edges {\n        node {\n          name\n          slug\n          buildDate\n          description\n          orderCustomerName\n          mainserviceobjectId\n          relationId\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var projectsQuery = gql(_templateObject());
export var TypedProjectDetailsQuery = TypedQuery(projectsQuery);