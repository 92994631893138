import React from "react";
import "./scss/index.scss";
import { Button, Loader } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import CatalogSearch from "@temp/components/HybrITComponents/CatalogCustomHeader/CatalogSearch/CatalogSearch";
import { MachineList } from "@temp/components/HybrITComponents";
import CatalogDropDown from "@temp/components/HybrITComponents/CatalogCustomHeader/CatalogDropdown/CatalogDropdown";
import { Breadcrumbs } from "../../components";

var Page = function Page(_ref) {
  var machines = _ref.machines,
      search = _ref.search,
      setSearch = _ref.setSearch,
      onLoadMore = _ref.onLoadMore,
      hasNextPage = _ref.hasNextPage,
      projectSelectFields = _ref.projectSelectFields,
      activeProjectSelectOption = _ref.activeProjectSelectOption,
      onFilterByProject = _ref.onFilterByProject,
      displayLoader = _ref.displayLoader;
  var breadcrumbs = [{
    link: ["/machine"].join(""),
    value: "Machines Overview"
  }];

  var FilterSection = function FilterSection() {
    return React.createElement("div", {
      className: "machine-page-input-section"
    }, React.createElement("div", {
      className: "machine-page-input-section__searchfield"
    }, React.createElement(CatalogSearch, {
      search: search,
      setSearch: setSearch
    })), React.createElement("div", {
      className: "machine-page-input-section__selectfield"
    }, React.createElement(CatalogDropDown, {
      onChange: onFilterByProject,
      options: projectSelectFields,
      value: projectSelectFields.find(function (option) {
        return option.value === activeProjectSelectOption;
      })
    })));
  };

  return React.createElement("div", {
    className: "collection"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), !displayLoader ? React.createElement(React.Fragment, null, machines && machines.length > 0 ? React.createElement(React.Fragment, null, React.createElement(FilterSection, null), React.createElement(MachineList, {
    machines: machines
  })) : React.createElement(VisconNotFound, {
    message: "No machines were found.",
    headerMsg: "No Machines Found"
  })) : React.createElement(Loader, null)), hasNextPage && React.createElement("div", {
    className: "machines-button__container"
  }, React.createElement(Button, {
    testingContext: "loadMoreMachinesButton",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "views.MachineOverview.Page.1138265409",
    defaultMessage: "Load more"
  }))));
};

export default Page;