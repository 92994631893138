import React from "react";
import Media from "react-media";
import { Thumbnail } from "@components/molecules";
import { Link } from "react-router-dom";
import { generateProductWithCategoryUrl } from "@temp/core/utils";
import "./scss/index.scss";
import { Money } from "@components/containers";
import { TypedProductsCategoryDataQuery } from "./queries";
export default function ShippedProductItem(_ref) {
  var shippedProduct = _ref.shippedProduct;
  var description = shippedProduct.description,
      isMiscItem = shippedProduct.isMiscItem,
      length = shippedProduct.length,
      partNumber = shippedProduct.partNumber,
      quantity = shippedProduct.quantity,
      salesAmountPrice = shippedProduct.salesAmountPrice,
      unitDescription = shippedProduct.unitDescription;
  var productTitle = description;

  var orNone = function orNone(val) {
    return typeof val === "number" && val > -1 ? val : typeof val === "string" && val !== "" ? val : "None";
  };

  var formatValue = function formatValue(val) {
    return React.createElement(Money, {
      money: {
        amount: orNone(val),
        currency: "EUR"
      }
    });
  };

  var sectionOneItems = [{
    key: "Item nr",
    value: partNumber
  }, {
    key: "Quantity",
    value: quantity === null || quantity === void 0 ? void 0 : quantity.toLocaleString("nl-NL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }, {
    key: "Sales price",
    value: formatValue(salesAmountPrice)
  }];
  var sectionTwoItems = [{
    key: "Item unit",
    value: unitDescription
  }, {
    key: "Miscellaneous",
    value: isMiscItem ? "Yes" : "No"
  }, {
    key: "Length",
    value: length
  }];

  var renderItem = function renderItem(item, i) {
    return React.createElement("div", {
      key: "".concat(item, "_").concat(i)
    }, React.createElement("div", {
      className: "order-products-meta-item"
    }, React.createElement("div", {
      className: "order-products-meta-item__key"
    }, item.key), React.createElement("div", {
      className: "order-products-meta-item__value"
    }, item.value)));
  };

  var mapSectionOneItems = sectionOneItems.map(function (item, i) {
    return renderItem(item, i);
  });
  var mapSectionTwoItems = sectionTwoItems.map(function (item, i) {
    return renderItem(item, i);
  });
  var withPartNumber = React.createElement(TypedProductsCategoryDataQuery, {
    errorPolicy: "all",
    loaderFull: true,
    variables: {
      slug: partNumber
    }
  }, function (data) {
    var _data$data, _data$data$product, _data$data2, _data$data2$product, _data$data3, _data$data3$product, _data$data3$product$c, _data$data4, _data$data4$product, _data$data4$product$c, _data$data5, _data$data5$product;

    var productExists = function productExists(value) {
      return value && value.data && value.data.product;
    };

    return React.createElement("div", null, React.createElement("div", {
      className: "grid-container"
    }, !productExists(data) || !data.data.product.images || data.data.product.images.length === 0 ? null : React.createElement("div", {
      className: "orders-item-image"
    }, React.createElement(Thumbnail, {
      source: {
        thumbnail: {
          url: data.data.product.images[0].url,
          alt: null
        },
        thumbnail2x: {
          url: null
        }
      }
    })), React.createElement("div", {
      className: "meta-section"
    }, productExists(data) ? React.createElement("div", {
      className: "order-products-title"
    }, React.createElement(Link, {
      to: generateProductWithCategoryUrl(data === null || data === void 0 ? void 0 : (_data$data = data.data) === null || _data$data === void 0 ? void 0 : (_data$data$product = _data$data.product) === null || _data$data$product === void 0 ? void 0 : _data$data$product.id, data === null || data === void 0 ? void 0 : (_data$data2 = data.data) === null || _data$data2 === void 0 ? void 0 : (_data$data2$product = _data$data2.product) === null || _data$data2$product === void 0 ? void 0 : _data$data2$product.name, data === null || data === void 0 ? void 0 : (_data$data3 = data.data) === null || _data$data3 === void 0 ? void 0 : (_data$data3$product = _data$data3.product) === null || _data$data3$product === void 0 ? void 0 : (_data$data3$product$c = _data$data3$product.category) === null || _data$data3$product$c === void 0 ? void 0 : _data$data3$product$c.id, data === null || data === void 0 ? void 0 : (_data$data4 = data.data) === null || _data$data4 === void 0 ? void 0 : (_data$data4$product = _data$data4.product) === null || _data$data4$product === void 0 ? void 0 : (_data$data4$product$c = _data$data4$product.category) === null || _data$data4$product$c === void 0 ? void 0 : _data$data4$product$c.name),
      key: "Link_".concat(data === null || data === void 0 ? void 0 : (_data$data5 = data.data) === null || _data$data5 === void 0 ? void 0 : (_data$data5$product = _data$data5.product) === null || _data$data5$product === void 0 ? void 0 : _data$data5$product.id)
    }, productTitle)) : React.createElement("div", {
      className: "order-products-title"
    }, productTitle), mapSectionOneItems), React.createElement("div", {
      className: "meta-section-two"
    }, React.createElement(Media, {
      query: {
        minWidth: "992px"
      },
      render: function render() {
        return React.createElement("div", {
          className: "order-products-title"
        });
      }
    }), mapSectionTwoItems)));
  });
  return typeof partNumber !== "string" || !partNumber ? React.createElement("div", null, React.createElement("div", {
    className: "grid-container"
  }, React.createElement("div", {
    className: "meta-section"
  }, React.createElement("div", {
    className: "order-products-title"
  }, productTitle), mapSectionOneItems), React.createElement("div", {
    className: "meta-section-two"
  }, React.createElement(Media, {
    query: {
      minWidth: "992px"
    },
    render: function render() {
      return React.createElement("div", {
        className: "order-products-title"
      });
    }
  }), mapSectionTwoItems))) : withPartNumber;
}