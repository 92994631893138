import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import ReactSelect from "react-select";
import { ThemeContext } from "styled-components";

/* This is usually very very bad practise but its part of ReactSelect
 * https://react-select.com/styles
 */
var optionStyle = function optionStyle(customTheme) {
  return {
    option: function option(provided, state) {
      return _objectSpread({}, provided, {
        alignItems: "center",
        backgroundColor: state.isSelected ? customTheme.colors.primaryLight : state.isFocused ? customTheme.colors.primaryTransparent : "white",
        color: state.isDisabled ? customTheme.colors.lightFont : customTheme.colors.dark,
        fontWeight: state.isSelected && customTheme.typography.boldFontWeight,
        margin: "0 auto",
        minHeight: "34px",
        height: "100%",
        verticalAlign: "middle",
        width: "95%"
      });
    },
    container: function container(provided) {
      return _objectSpread({}, provided, {
        height: "100%",
        maxHeight: 48
      });
    },
    control: function control(provided) {
      return _objectSpread({}, provided, {
        height: "100%"
      });
    }
  };
};

var CatalogDropdown = function CatalogDropdown(_ref) {
  var name = _ref.name,
      options = _ref.options,
      value = _ref.value,
      onChange = _ref.onChange;
  var customTheme = React.useContext(ThemeContext);
  return React.createElement(React.Fragment, null, React.createElement(ReactSelect, {
    name: name,
    options: options,
    value: value,
    onChange: onChange,
    styles: _objectSpread({}, optionStyle(customTheme))
  }));
};

export default CatalogDropdown;