import React from "react";
import Media from "react-responsive";
import { mediumScreen } from "@styles/constants";
import LogoSmall from "../../../../images/logo-small.svg";
import * as S from "./styles";
export var DemoBanner = function DemoBanner() {
  return React.createElement(S.Wrapper, null, React.createElement(S.BorderedWrapper, null, React.createElement("a", {
    target: "_blank",
    href: "https://saleor.io/"
  }, React.createElement(S.LogoWrapper, {
    path: LogoSmall
  })), React.createElement(S.LinkList, null, React.createElement(Media, {
    maxWidth: mediumScreen
  }, React.createElement(S.Link, {
    target: "_blank",
    href: "https://pwa.demo.saleor.rocks/graphql/"
  }, React.createElement(S.TextEmphasis, null, "API")), React.createElement(S.Divider, null), React.createElement(S.Link, {
    target: "_blank",
    href: "https://pwa.saleor.io/dashboard/"
  }, React.createElement(S.TextEmphasis, null, "Dashboard"))), React.createElement(Media, {
    minWidth: mediumScreen
  }, React.createElement(S.Link, {
    target: "_blank",
    href: "https://pwa.saleor.io/dashboard/"
  }, "Explore ", React.createElement(S.TextEmphasis, null, "Store\u2019s dashboard")), React.createElement(S.Divider, null), React.createElement(S.Link, {
    target: "_blank",
    href: "https://pwa.demo.saleor.rocks/graphql/"
  }, "Play with ", React.createElement(S.TextEmphasis, null, "GraphQL API"))))));
};