import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import { OfferList } from "../../components/HybrITComponents/OfferList";
import CatalogSearch from "../../components/HybrITComponents/CatalogCustomHeader/CatalogSearch/CatalogSearch";
import { Breadcrumbs } from "../../components";
import * as S from "./styles";
var ITEMS_PER_PAGE = 200;
var breadcrumbs = [{
  link: ["/offers"].join(""),
  value: "Offers / Orders"
}];

var handleVisconNotFound = function handleVisconNotFound(data, d) {
  return data === null || data === undefined || data.length < 1 ? React.createElement(VisconNotFound, {
    message: "No Offers / Orders were found in your account."
  }) : false;
};

export default function Page(_ref) {
  var history = _ref.history,
      offers = _ref.offers,
      search = _ref.search,
      setSearch = _ref.setSearch;

  var _React$useState = React.useState(ITEMS_PER_PAGE),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      offerCounter = _React$useState2[0],
      setOfferCounter = _React$useState2[1];

  var loadmoreOffers = function loadmoreOffers() {
    setOfferCounter(offerCounter + ITEMS_PER_PAGE);
  };

  return React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "offers-page"
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "offers-page__search-field"
  }, React.createElement(CatalogSearch, {
    search: search,
    setSearch: setSearch,
    descriptionPlaceHolder: "Offer / Order Number"
  })), React.createElement("div", {
    className: "offers-page__offers-list"
  }, React.createElement(OfferList, {
    history: history,
    offers: offers,
    offerCounter: offerCounter
  })), handleVisconNotFound(offers)), React.createElement(S.Wrapper, null, (offers === null || offers === void 0 ? void 0 : offers.length) > offerCounter ? React.createElement(Button, {
    testingContext: "loadMoreOffersButton",
    className: "button cart-button navigation",
    onClick: function onClick() {
      loadmoreOffers();
    }
  }, React.createElement(FormattedMessage, {
    id: "views.OfferOverview.Page.1138265409",
    defaultMessage: "Load more"
  })) : null)));
}