import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import "./scss/index.scss";
import React, { useCallback } from "react";
import { generateProductWithCategoryUrl } from "@temp/core/utils";
import { Money } from "@components/containers/Money";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import ReactSVG from "react-svg";
import { useCart } from "@saleor/sdk";
import { TypedReplacementProductDetailsDataQuery, TypedAlternativeProductDetailsDataQuery } from "./queries";
import increaseQuantityImg from "../../../../images/increase-quantity.svg";
import decreaseQuantityImg from "../../../../images/decrease-quantity.svg";
import cartImg from "../../../../images/cart.svg";

var MetaDataItem = function MetaDataItem(_ref) {
  var metaKey = _ref.metaKey,
      metaValue = _ref.metaValue;
  return React.createElement("div", {
    className: "list-view-item__content__meta__item"
  }, React.createElement("span", null, metaKey), React.createElement("span", null, metaValue || "None"));
};

export var MetaDataBlocks = function MetaDataBlocks(_ref2) {
  var metadata = _ref2.metadata,
      stockQuantity = _ref2.stockQuantity,
      category = _ref2.category,
      slug = _ref2.slug,
      description = _ref2.description,
      showReplacements = _ref2.showReplacements,
      replacementProductUrl = _ref2.replacementProductUrl,
      alternativeProductUrl = _ref2.alternativeProductUrl;
  if (metadata === undefined || metadata.length === 0) return React.createElement("div", null, "No Meta Data found for this product");
  var aggregatedAttributes = [].concat(_toConsumableArray(metadata), [{
    key: "categoryName",
    value: (category === null || category === void 0 ? void 0 : category.name) || null,
    __typename: "MetadataItem"
  }, {
    key: "slug",
    value: slug,
    __typename: "MetadataItem"
  }, {
    key: "description",
    value: description,
    __typename: "MetadataItem"
  }, {
    key: "stockQuantity",
    value: (stockQuantity === null || stockQuantity === void 0 ? void 0 : stockQuantity.toString()) || null,
    __typename: "MetadataItem"
  }]);
  return React.createElement("div", {
    className: "metaItems"
  }, showReplacements ? React.createElement("div", {
    className: "list-view-item__content__meta__replacements"
  }, [metadata === null || metadata === void 0 ? void 0 : metadata.find(function (item) {
    return item.key === "alternativeProductSlug";
  })].map(function (item, i) {
    return (item === null || item === void 0 ? void 0 : item.value) ? React.createElement(MetaDataItem, {
      metaKey: "Alternative product",
      metaValue: React.createElement(Link, {
        to: alternativeProductUrl,
        key: "Link_".concat(item === null || item === void 0 ? void 0 : item.value)
      }, React.createElement("span", {
        className: "list-view-item__content__meta__replacements__link"
      }, item === null || item === void 0 ? void 0 : item.value)),
      key: "metaitem_".concat(i)
    }) : null;
  }), [metadata === null || metadata === void 0 ? void 0 : metadata.find(function (item) {
    return item.key === "replacementProductSlug";
  })].map(function (item, i) {
    return (item === null || item === void 0 ? void 0 : item.value) ? React.createElement(MetaDataItem, {
      metaKey: "Replacement product",
      metaValue: React.createElement(Link, {
        to: replacementProductUrl,
        key: "Link_".concat(item === null || item === void 0 ? void 0 : item.value)
      }, React.createElement("span", {
        className: "list-view-item__content__meta__replacements__link"
      }, item === null || item === void 0 ? void 0 : item.value)),
      key: "metaitem_".concat(i)
    }) : null;
  })) : null, [aggregatedAttributes.find(function (item) {
    return item.key === "slug";
  })].map(function (item, i) {
    return React.createElement(MetaDataItem, {
      metaKey: "Item nr.",
      metaValue: item === null || item === void 0 ? void 0 : item.value,
      key: "metaitem_".concat(i)
    });
  }), [aggregatedAttributes.find(function (item) {
    return item.key === "itemUnit";
  })].map(function (item, i) {
    return React.createElement(MetaDataItem, {
      metaKey: "Item unit",
      metaValue: item === null || item === void 0 ? void 0 : item.value,
      key: "metaitem_".concat(i)
    });
  }), [aggregatedAttributes.find(function (item) {
    return item.key === "categoryName";
  })].map(function (item, i) {
    return React.createElement(MetaDataItem, {
      metaKey: "Item group",
      metaValue: item === null || item === void 0 ? void 0 : item.value,
      key: "metaitem_".concat(i)
    });
  }), [aggregatedAttributes.find(function (item) {
    return item.key === "itemSubGroup";
  })].map(function (item, i) {
    return React.createElement(MetaDataItem, {
      metaKey: "Item subgroup",
      metaValue: item === null || item === void 0 ? void 0 : item.value,
      key: "metaitem_".concat(i)
    });
  }), [aggregatedAttributes.find(function (item) {
    return item.key === "stockQuantity";
  })].map(function (item, i) {
    return React.createElement(MetaDataItem, {
      metaKey: "Current stock",
      metaValue: item === null || item === void 0 ? void 0 : item.value,
      key: "metaitem_".concat(i)
    });
  }));
};

var ActionBlock = function ActionBlock(_ref3) {
  var price = _ref3.price,
      addToCart = _ref3.addToCart,
      productId = _ref3.productId,
      isDisabled = _ref3.isDisabled;

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      quantity = _React$useState2[0],
      setQuantity = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      tempQuantity = _React$useState4[0],
      setTempQuantity = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      showInput = _React$useState6[0],
      setShowInput = _React$useState6[1];

  var setFocus = useCallback(function (inputElement) {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  var handleQuantityClick = function handleQuantityClick() {
    if (quantity < 1) {
      setQuantity(0);
    }

    setShowInput(!showInput);
  };

  var handleQuantityInput = function handleQuantityInput(e) {
    setTempQuantity(e.target.value.replace(",", "."));
  };

  var handleQuantityBlur = function handleQuantityBlur(e) {
    setQuantity(parseFloat(tempQuantity) || 0);
    setShowInput(false);
  };

  var handleQuantityFocus = function handleQuantityFocus(e) {
    if (quantity !== 0) {
      setTempQuantity(quantity.toString());
    }

    setShowInput(true);
  };

  var quantityInput = React.createElement("input", {
    type: "text",
    className: "quantity-input",
    value: tempQuantity,
    onChange: handleQuantityInput,
    onFocus: handleQuantityFocus,
    onBlur: handleQuantityBlur,
    ref: setFocus
  });
  return React.createElement("div", {
    className: "action-block"
  }, React.createElement("div", {
    className: "price-block"
  }, React.createElement("span", {
    className: "price"
  }, React.createElement("p", {
    className: "show-as-flex"
  }, "PPU"), React.createElement("p", {
    className: "show-as-flex"
  }, React.createElement(Money, {
    money: {
      amount: price.amount,
      currency: "EUR"
    }
  }))), React.createElement("span", {
    className: "price"
  }, React.createElement("p", {
    className: "show-as-flex"
  }, "Total"), React.createElement("p", {
    className: "show-as-flex"
  }, React.createElement(Money, {
    money: {
      amount: price.amount * quantity,
      currency: "EUR"
    }
  })))), React.createElement("div", {
    className: "cart-actions"
  }, React.createElement("div", {
    className: "select-quantity"
  }, !isDisabled ? React.createElement(React.Fragment, null, React.createElement(ReactSVG, {
    onClick: function onClick(e) {
      e.preventDefault();
      if (quantity <= 0) return;
      setQuantity(quantity - 1 <= 0 ? 0 : quantity - 1);
    },
    path: decreaseQuantityImg,
    className: "quantity__button"
  }), React.createElement("div", {
    className: "quantity"
  }, React.createElement("span", {
    onClick: handleQuantityClick
  }, showInput ? quantityInput : React.createElement("div", {
    className: "numerical"
  }, quantity) || 0)), React.createElement(ReactSVG, {
    onClick: function onClick(e) {
      e.preventDefault();
      setQuantity(quantity + 1);
    },
    path: increaseQuantityImg,
    className: "quantity__button"
  })) : React.createElement("div", null)), !isDisabled ? React.createElement("button", {
    className: "button cart-button navigation",
    onClick: function onClick(e) {
      addToCart(e, productId, quantity);
    }
  }, React.createElement(ReactSVG, {
    path: cartImg,
    className: "cartIcon"
  })) : React.createElement("button", {
    disabled: true,
    className: "button cart-button navigation",
    onClick: function onClick() {
      return null;
    }
  }, React.createElement(ReactSVG, {
    path: cartImg,
    className: "cartIcon"
  }))));
};

export default function ListViewItem(_ref4) {
  var _product$metadata$fin, _product$metadata$fin2;

  var product = _ref4.product;

  var _useCart = useCart(),
      addItem = _useCart.addItem;

  var replacementProductSlug = product === null || product === void 0 ? void 0 : (_product$metadata$fin = product.metadata.find(function (obj) {
    return obj.key === "replacementProductSlug";
  })) === null || _product$metadata$fin === void 0 ? void 0 : _product$metadata$fin.value;
  var alternativeProductSlug = product === null || product === void 0 ? void 0 : (_product$metadata$fin2 = product.metadata.find(function (obj) {
    return obj.key === "alternativeProductSlug";
  })) === null || _product$metadata$fin2 === void 0 ? void 0 : _product$metadata$fin2.value;
  var unmarketable = product === null || product === void 0 ? void 0 : product.metadata.find(function (obj) {
    return obj.key === "unmarketable";
  });
  var isUnmarketable = unmarketable !== null && unmarketable !== undefined ? JSON.parse(unmarketable.value) : null;
  var noReplacement = product === null || product === void 0 ? void 0 : product.metadata.find(function (obj) {
    return obj.key === "noreplacement";
  });
  var hasNoReplacement = noReplacement !== null && noReplacement !== undefined ? JSON.parse(noReplacement.value) : null;
  var showReplacements = isUnmarketable && !hasNoReplacement;
  var isDisabled = isUnmarketable;

  var addToCart = function addToCart(e, productId, quantity) {
    e.preventDefault();

    if (quantity < 1) {
      return;
    }

    addItem(productId, quantity);
  };

  return React.createElement(TypedReplacementProductDetailsDataQuery, {
    errorPolicy: "all",
    loaderFull: true,
    variables: {
      slug: replacementProductSlug || ""
    }
  }, function (replacementProductData) {
    var _replacementProductDa;

    var replacementProduct = replacementProductData === null || replacementProductData === void 0 ? void 0 : (_replacementProductDa = replacementProductData.data) === null || _replacementProductDa === void 0 ? void 0 : _replacementProductDa.product;
    return React.createElement(TypedAlternativeProductDetailsDataQuery, {
      errorPolicy: "all",
      loaderFull: true,
      variables: {
        slug: alternativeProductSlug || ""
      }
    }, function (alternativeProductData) {
      var _alternativeProductDa;

      var alternativeProduct = alternativeProductData === null || alternativeProductData === void 0 ? void 0 : (_alternativeProductDa = alternativeProductData.data) === null || _alternativeProductDa === void 0 ? void 0 : _alternativeProductDa.product;
      return React.createElement("div", {
        className: "list-view-item"
      }, React.createElement("div", {
        className: "list-view-item__container"
      }, React.createElement("div", {
        className: "list-view-item__image"
      }, React.createElement(Thumbnail, {
        source: product
      })), React.createElement("div", {
        className: "list-view-item__content"
      }, React.createElement(Link, {
        to: generateProductWithCategoryUrl(product.id, product.name, product.category.id, product.category.name),
        key: "Link_".concat(product.id)
      }, React.createElement("h3", {
        className: "list-view-item__content__title"
      }, product.name)), isUnmarketable && React.createElement("div", {
        className: "list-view-item__content__subtitle"
      }, "This product is no longer available"), React.createElement("div", {
        className: "list-view-item__content__meta"
      }, React.createElement(MetaDataBlocks, {
        key: "Meta_".concat(product.id),
        metadata: product.metadata,
        slug: product.slug,
        category: product.category,
        stockQuantity: product === null || product === void 0 ? void 0 : product.variants[0].stocks[0].quantity,
        description: product.description,
        showReplacements: showReplacements,
        replacementProductUrl: replacementProduct ? generateProductWithCategoryUrl(replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.name, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.id, replacementProduct === null || replacementProduct === void 0 ? void 0 : replacementProduct.category.name) : "",
        alternativeProductUrl: alternativeProduct ? generateProductWithCategoryUrl(alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.name, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.id, alternativeProduct === null || alternativeProduct === void 0 ? void 0 : alternativeProduct.category.name) : ""
      }), React.createElement(ActionBlock, {
        addToCart: addToCart,
        productId: product.variants[0].id,
        price: product.variants[0].price,
        isDisabled: isDisabled
      })))));
    });
  });
}