import * as React from "react";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import { generateProductWithCategoryUrl } from "../../../core/utils";

var ProductItem = function ProductItem(_ref) {
  var product = _ref.node;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateProductWithCategoryUrl(product.id, product.name, product.category.id, product.category.name)
  }, React.createElement(Thumbnail, {
    source: product
  }), React.createElement("span", null, React.createElement("h4", null, product.name), product.slug, React.createElement("p", null, product.category.name))));
};

export default ProductItem;