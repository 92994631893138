import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { CartPage, CheckoutPage, PasswordReset, OrderThankYouPage, OfferThankYouPage } from "@pages";
import { useAuth } from "@saleor/sdk";
import { CheckoutLogin, NotFound, Loader } from "../../components";
import UserAccount, * as accountPaths from "../../userAccount/routes";
import { OrderDetails } from "../../userAccount/views";
import { Account, AccountConfirm } from "../../views/Account";
import { ArticlePage } from "../../views/Article";
import { CategoryPage } from "../../views/Category";
import { PartsPage } from "../../views/Parts";
import { CollectionPage } from "../../views/Collection";
import { HomePage } from "../../views/Home";
import { ProductPage } from "../../views/Product";
import { SearchPage } from "../../views/Search";
import { LoginPage } from "../../views/Login";
import { ProjectPage } from "../../views/Project";
import { OrderOverviewPage } from "../../views/OrderOverview";
import { OfferOverviewPage } from "../../views/OfferOverview";
import { OfferDetailPage } from "../../views/OfferDetail";
import { OrderDetailPage } from "../../views/OrderDetail";
import { ProjectDetailPage } from "../../views/ProjectDetail";
import { MachineOverviewPage } from "../../views/MachineOverview";
import { MachineDetailPage } from "../../views/MachineDetail";
import * as paths from "./paths";
import { ProtectedRoute } from "./ProtectedRoute";
var defaultProtectedRouteProps = {
  isAuthenticated: false,
  authenticationPath: "/login"
};
export var Routes = function Routes() {
  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated,
      loaded = _useAuth.loaded;

  if (!loaded) return React.createElement(Loader, null);
  return React.createElement(Switch, null, React.createElement(Route, {
    path: paths.loginUrl,
    component: LoginPage
  }), React.createElement(Route, {
    path: paths.passwordResetUrl,
    component: PasswordReset
  }), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    exact: true,
    path: paths.baseUrl,
    component: HomePage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.projectDetailUrl,
    component: ProjectDetailPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.orderDetailUrl,
    component: OrderDetailPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.offerDetailUrl,
    component: OfferDetailPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.offersOverviewUrl,
    component: OfferOverviewPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.orderHistoryUrl,
    component: OrderOverviewPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.machinesUrl,
    component: MachineOverviewPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.machineDetailUrl,
    component: MachineDetailPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.projectsOverviewUrl,
    component: ProjectPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.searchUrl,
    component: SearchPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.categoryUrl,
    component: CategoryPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.collectionUrl,
    component: CollectionPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.productUrl,
    component: ProductPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.partsUrl,
    component: PartsPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.cartUrl,
    component: CartPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.checkoutLoginUrl,
    component: CheckoutLogin
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.pageUrl,
    component: ArticlePage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: accountPaths.baseUrl,
    component: UserAccount
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: accountPaths.userOrderDetailsUrl,
    component: OrderDetails
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.guestOrderDetailsUrl,
    component: OrderDetails
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.accountUrl,
    component: Account
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.accountConfirmUrl,
    component: AccountConfirm
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.addressBookUrl,
    component: Account
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.orderList,
    component: Account
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.offerList,
    component: Account
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.checkoutUrl,
    component: CheckoutPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.orderFinalizedUrl,
    component: OrderThankYouPage
  })), React.createElement(ProtectedRoute, _extends({}, defaultProtectedRouteProps, {
    isAuthenticated: authenticated,
    path: paths.offerFinalizedUrl,
    component: OfferThankYouPage
  })), React.createElement(Route, {
    component: NotFound
  }));
};
export default Routes;