import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  query ProjectSelect {\n    projects(first: 100) {\n      totalCount\n      edges {\n        node {\n          name\n          mainserviceobjectId\n          relationId\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query MachinesPerProject(\n    $search: String\n    $after: String\n    $mainserviceobjectId: String\n  ) {\n    collections(\n      after: $after\n      first: 100\n      filter: { mainserviceobjectId: $mainserviceobjectId, search: $search }\n    ) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          mainserviceobjectId\n          relationId\n          isPublished\n          backgroundImage {\n            url\n          }\n          publicationDate\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Machines($search: String, $after: String) {\n    collections(after: $after, first: 100, filter: { search: $search }) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          name\n          relationId\n          isPublished\n          backgroundImage {\n            url\n          }\n          publicationDate\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var machinesQuery = gql(_templateObject());
export var machinesPerProjectQuery = gql(_templateObject2());
export var projectSelectQuery = gql(_templateObject3());
export var TypedMachinesQuery = TypedQuery(machinesQuery);
export var TypedMachinesPerProjectQuery = TypedQuery(machinesPerProjectQuery);
export var TypedProjectSelectQuery = TypedQuery(projectSelectQuery);