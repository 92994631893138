import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query CollectionProducts(\n    $id: ID!\n    $attributes: [AttributeInput]\n    $after: String\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $priceLte: Float\n    $priceGte: Float\n  ) {\n    products(\n      after: $after\n      first: $pageSize\n      sortBy: $sortBy\n      filter: {\n        attributes: $attributes\n        collections: [$id]\n        minimalPrice: { gte: $priceGte, lte: $priceLte }\n      }\n    ) {\n      totalCount\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n          category {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Collection($id: ID!) {\n    collection(id: $id) {\n      id\n      slug\n      name\n      seoDescription\n      seoTitle\n      backgroundImage {\n        url\n      }\n    }\n    attributes(\n      filter: { inCollection: $id, filterableInStorefront: true }\n      first: 100\n    ) {\n      edges {\n        node {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../Product/queries";
export var collectionProductsDataQuery = gql(_templateObject());
export var TypedCollectionProductsDataQuery = TypedQuery(collectionProductsDataQuery);
export var collectionProductsQuery = gql(_templateObject2(), basicProductFragment, productPricingFragment);
export var TypedCollectionProductsQuery = TypedQuery(collectionProductsQuery);