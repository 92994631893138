import React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { OverlayTheme, OverlayType, MainLogo } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import { TypedMainMenuQuery } from "./queries";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import logoImg from "../../images/viscon-logo-2.svg";
import logoSmallImg from "../../images/logo-small.png";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
import defaultPages from "./defaultPages";
export var RenderMainMenuLeft = function RenderMainMenuLeft(props) {
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement(Link, {
        to: appPaths.baseUrl
      }, React.createElement(MainLogo, {
        imageLarge: logoImg,
        imageSmall: logoSmallImg
      }));
    }
  }), props.user && React.createElement(TypedMainMenuQuery, {
    renderOnError: true,
    displayLoader: false
  }, function (_ref) {
    var data = _ref.data;
    var itemsWithDefaults = defaultPages.concat(data.shop.navigation.main.items);
    var items = maybe(function () {
      return itemsWithDefaults;
    }, []);
    return React.createElement("ul", null, React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("li", {
          "data-test": "toggleSideMenuLink",
          className: "main-menu__hamburger",
          onClick: function onClick() {
            return props.overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
              data: items
            });
          }
        }, React.createElement(ReactSVG, {
          path: hamburgerImg,
          className: "main-menu__hamburger--icon"
        }), React.createElement(ReactSVG, {
          path: hamburgerHoverImg,
          className: "main-menu__hamburger--hover"
        }));
      }
    }));
  }));
};