import React from "react";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { BraintreePaymentGateway, DummyPaymentGateway, StripePaymentGateway } from "..";
import * as S from "./styles";

/**
 * Payment Gateways list
 */
var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      errors = _ref.errors,
      onError = _ref.onError,
      checkedPaymentOption = _ref.checkedPaymentOption,
      setCheckedPaymentOption = _ref.setCheckedPaymentOption;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;

    // const checked = true;
    // selectedPaymentGateway === id;
    function handleOfferTile(tileId) {
      selectPaymentGateway(tileId);
      return setCheckedPaymentOption("offer");
    }

    function handleOrderTile(tileId) {
      selectPaymentGateway(tileId);
      return setCheckedPaymentOption("order");
    }

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: "other"
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: true,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.OfferTile, {
          checked: checkedPaymentOption
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checkedPaymentOption === "offer",
          onChange: function onChange() {
            return handleOfferTile(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, "Request offer"))), React.createElement(S.OrderTile, {
          checked: checkedPaymentOption
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checkedPaymentOption === "order",
          onChange: function onChange() {
            return handleOrderTile(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, "Place order directly"))), React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.STRIPE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: "other"
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayStripeInput",
          name: "payment-method",
          value: "stripe",
          checked: true,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayStripeName"
        }, name))), React.createElement(StripePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };