import React from "react";
import { DemoBanner } from "@components/atoms";
import { demoMode } from "@temp/constants";
import { Footer, MainMenu, MetaConsumer, OverlayManager } from "../components";
import "../globalStyles/scss/index.scss";
import { Routes } from "./routes";
import withAppInsights from "../appInsights";

var App = function App() {
  return React.createElement(React.Fragment, null, React.createElement(MetaConsumer, null), demoMode && React.createElement(DemoBanner, null), React.createElement("header", null, React.createElement(MainMenu, null)), React.createElement("div", {
    className: "container-flex"
  }, React.createElement(Routes, null)), React.createElement(Footer, null), React.createElement(OverlayManager, null));
};

export default withAppInsights(App);