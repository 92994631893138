import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
import { Redirect, Route } from "react-router";
export var ProtectedRoute = function ProtectedRoute(props) {
  // defaults to homepage
  var redirectPath = "";

  if (!props.isAuthenticated) {
    redirectPath = props.authenticationPath;
  }

  if (redirectPath) {
    var renderComponent = function renderComponent() {
      return React.createElement(Redirect, {
        to: {
          pathname: redirectPath
        }
      });
    };

    return React.createElement(Route, _extends({}, props, {
      component: renderComponent,
      render: undefined
    }));
  }

  return React.createElement(Route, props);
};
export default ProtectedRoute;