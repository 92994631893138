import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Money } from "@components/containers";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.Type, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.1952810469",
    defaultMessage: "Type"
  })), React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)), React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.4055045245",
    defaultMessage: "Number"
  })), React.createElement(S.Description, null, "Description"), matches ? React.createElement(React.Fragment, null, React.createElement(S.Total, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.878013594",
    defaultMessage: "Total"
  })), React.createElement(S.DateOfOffer, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.4205493358",
    defaultMessage: "Date"
  })), React.createElement(S.Reference, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.3071833285",
    defaultMessage: "Reference"
  })), React.createElement(S.ContactPerson, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.2535071452",
    defaultMessage: "Contact Person"
  })), React.createElement(S.Incoterm, null, "IncoTerm")) : React.createElement(React.Fragment, null, React.createElement(S.ContactPerson, null, React.createElement(FormattedMessage, {
    id: "components.HybrITComponents.OfferList.OfferList.2535071452",
    defaultMessage: "Contact Person"
  }))));
};

export var OfferList = function OfferList(_ref) {
  var offers = _ref.offers,
      offerCounter = _ref.offerCounter;
  var theme = React.useContext(ThemeContext);
  var renderOffers = offers ? offers === null || offers === void 0 ? void 0 : offers.slice(0, offerCounter) : offers;
  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["orange"] = "#FFA500";
    statusColors["green"] = "#7fbf7f";
  })(statusColors || (statusColors = {}));

  var statusColor = function statusColor(status) {
    switch (status) {
      case "Submitted":
        return React.createElement("span", {
          style: {
            color: statusColors.orange
          }
        }, "Submitted");

      case "Order":
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "In Review");
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches)), renderOffers ? renderOffers.map(function (offer) {
      var date = new Date(offer.offerDate);
      return React.createElement(Link, {
        key: offer.offerNumber,
        to: "/offer-detail/?offerId=".concat(offer.offerId)
      }, React.createElement(S.Row, {
        "data-test": "offerEntry",
        "data-test-id": offer.offerNumber
      }, React.createElement(S.Type, null, offer && offer.hasOwnProperty("isOrder") ? offer.isOrder ? "Order" : "Offer" : null), React.createElement(S.Status, null, statusColor(offer.status)), React.createElement(S.IndexNumber, null, offer.offerNumber), React.createElement(S.Description, null, offer.description), matches ? React.createElement(React.Fragment, null, React.createElement(S.Total, null, React.createElement(Money, {
        money: {
          amount: offer.totalValue,
          currency: "EUR"
        }
      })), React.createElement(S.DateOfOffer, null, React.createElement(FormattedDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        value: date
      })), React.createElement(S.Reference, null, offer.reference || "None"), React.createElement(S.ContactPerson, null, offer.contactPerson), React.createElement(S.Incoterm, null, offer.incoTerm)) : React.createElement(React.Fragment, null, React.createElement(S.ContactPerson, null, offer.contactPerson))));
    }) : null);
  }));
};