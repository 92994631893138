import _typeof from "@babel/runtime/helpers/typeof";
import "./scss/index.scss";
import React from "react";
import { commonMessages } from "@temp/intl";
import { FormattedMessage } from "react-intl";
import VisconNotFound from "@temp/components/HybrITComponents/VisconNotFound";
import { OrderInformationList } from "../../components/HybrITComponents/OrderInformationList";
import { OrderShippedProductItem } from "../../components/HybrITComponents/OrderShippedProductItem";
import { Breadcrumbs } from "../../components";
export default function Page(_ref) {
  var _order$description;

  var order = _ref.order,
      shippedProducts = _ref.shippedProducts,
      status = _ref.status;
  var title = order ? "Order - ".concat((_order$description = order.description) === null || _order$description === void 0 ? void 0 : _order$description.toString()) : "Order - ";

  var populateBreadcrumbs = function populateBreadcrumbs(order) {
    return [{
      link: ["/order-history"].join(""),
      value: "Order history"
    }, {
      link: "/order-detail/?orderId=".concat(order && order.orderId),
      value: title
    }];
  };

  var statusColors;

  (function (statusColors) {
    statusColors["darkRed"] = "#8b0000";
    statusColors["darkOrange"] = "#ff8c00";
    statusColors["orange"] = "#FFA500";
    statusColors["darkGreen"] = "#006400";
    statusColors["middleGreen"] = "#329932";
    statusColors["green"] = "#7fbf7f";
    statusColors["lightRed"] = "#ff4c4c";
  })(statusColors || (statusColors = {}));

  var StatusColor = function StatusColor(_ref2) {
    var status = _ref2.status;

    switch (status) {
      case 1:
        return React.createElement("span", {
          style: {
            color: statusColors.darkRed
          }
        }, "New");

      case 2:
      case 3:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Shipping");

      case 4:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoice");

      case 5:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices printed");

      case 6:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "Invoices Processed");

      case 7:
        return React.createElement("span", {
          style: {
            color: statusColors.green
          }
        }, "Order confirmation printed");

      default:
        return React.createElement("span", {
          style: {
            color: statusColors.darkOrange
          }
        }, "None");
    }
  };

  var handleVisconNotFound = function handleVisconNotFound(data, d) {
    return data === null || data === undefined || _typeof(data) !== "object" ? React.createElement(VisconNotFound, {
      message: "Sorry, the order cannot not be displayed at the moment",
      messageTwo: "Please try again at a later moment.",
      headerMsg: "Order cannot be displayed",
      buttonText: "Back to orders",
      buttonUrl: "/orders"
    }) : false;
  };

  return React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "order-detail-page"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(order)
  }), handleVisconNotFound(order) || React.createElement(React.Fragment, null, React.createElement("h3", {
    className: "order-detail-page__title"
  }, title), order && order.customerOrderNumber ? React.createElement("div", null, React.createElement("span", {
    className: "field-info-key"
  }, "Reference:"), " ", order.customerOrderNumber) : null, React.createElement("div", null, React.createElement("span", {
    className: "field-info-key"
  }, React.createElement(FormattedMessage, commonMessages.status), ": "), React.createElement(StatusColor, {
    status: status
  })), React.createElement("div", {
    className: "order-detail-page__list"
  }), React.createElement(OrderInformationList, {
    orderMetaItems: order
  })), React.createElement("div", {
    className: "order-detail-page__products-title"
  }, "Products"), shippedProducts && shippedProducts.length > 0 ? shippedProducts.map(function (item, i) {
    return React.createElement(OrderShippedProductItem, {
      key: "shippedProduct_".concat(i),
      shippedProduct: item
    });
  }) : "No product information available"));
}