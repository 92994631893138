var slugRegex = ":slug([a-z-0-9]+)/:id([0-9]+)";
var optionalSlugRegex = ":slug([a-z-0-9]+)?/:id([0-9]+)?";
var slugUrl = "".concat(slugRegex, "/");
var productSlugUrl = ":categoryslug([a-z-0-9]+)/:catgoryid([0-9]+)/".concat(slugRegex);
export var baseUrl = "/";
export var searchUrl = "".concat(baseUrl, "search/");
export var productsearchUrl = "".concat(baseUrl, "product");
export var categoryUrl = "".concat(baseUrl, "category/").concat(slugUrl);
export var collectionUrl = "".concat(baseUrl, "collection/").concat(slugUrl);
export var basePartsUrl = "".concat(baseUrl, "product/");
export var partsUrl = "".concat(baseUrl, "product/").concat(optionalSlugRegex);
export var productUrl = "".concat(baseUrl, "product/").concat(productSlugUrl);
export var cartUrl = "".concat(baseUrl, "cart/:token?/");
export var loginUrl = "".concat(baseUrl, "login/");
export var checkoutLoginUrl = "".concat(baseUrl, "checkout/login");
export var pageUrl = "".concat(baseUrl, "page/:slug/");
export var guestOrderDetailsUrl = "/order-history/:token/";
export var accountUrl = "".concat(baseUrl, "account/");
export var accountConfirmUrl = "".concat(baseUrl, "account-confirm/");
export var orderHistoryUrl = "".concat(baseUrl, "order-history/");
export var addressBookUrl = "".concat(baseUrl, "address-book/");
export var passwordResetUrl = "".concat(baseUrl, "reset-password/");
export var checkoutUrl = "".concat(baseUrl, "checkout/");
export var orderFinalizedUrl = "".concat(baseUrl, "order-finalized/");
export var offerFinalizedUrl = "".concat(baseUrl, "offer-finalized/");
export var projectsOverviewUrl = "".concat(baseUrl, "project/");
export var machinesUrl = "".concat(baseUrl, "machine/");
export var machineDetailUrl = "".concat(baseUrl, "machine-detail/");
export var offersOverviewUrl = "".concat(baseUrl, "offers/");
export var orderDetailUrl = "".concat(baseUrl, "order-detail/");
export var offerDetailUrl = "".concat(baseUrl, "offer-detail/");
export var projectDetailUrl = "".concat(baseUrl, "project-detail/");
export var offerList = "".concat(baseUrl, "my-offers/");
export var orderList = "".concat(baseUrl, "my-orders/");