import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";
import { Button } from "@components/atoms";
import formatSlug from "./util/formatSlug";
var reader = new BrowserMultiFormatReader();

var ReaderComponent = function ReaderComponent(_ref) {
  var history = _ref.history,
      reader = _ref.reader,
      isScanning = _ref.isScanning,
      handleReader = _ref.handleReader;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      slug = _React$useState2[0],
      setSlug = _React$useState2[1];

  var redirectLink;

  if (reader && isScanning) {
    reader.listVideoInputDevices().then(function (videoInputDevices) {
      // Undefined is passed here in order to prefer the environment camera
      reader.decodeFromVideoDevice(undefined, "video", function (result, err) {
        if (result) {
          if (result.text && result.text !== slug) {
            setSlug(result.text);
          }
        }

        if (err && !(err instanceof NotFoundException)) console.error("Instance Error", err);
      });
    }).catch(function (err) {
      return console.error("QR Error", err);
    });
  }

  if (slug) {
    if (slug.includes(".")) {
      redirectLink = "/machine?search=".concat(formatSlug(slug));
    } else {
      redirectLink = "/product?search=".concat(formatSlug(slug));
    }

    reader.reset();
    history.push(redirectLink);
  }

  return React.createElement(Button, {
    className: "QR_Scanner__Button_stop button cart-button navigation",
    testingContext: "stop_QR_scan",
    onClick: function onClick() {
      return handleReader(false);
    }
  }, "Stop Scanning");
};

var QRScanner = function QRScanner(_ref2) {
  var history = _ref2.history;

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isScanning = _React$useState4[0],
      setScanning = _React$useState4[1];

  React.useEffect(function () {
    reader.reset();
  }, [isScanning]);

  var handleReader = function handleReader(scanning) {
    if (scanning) {
      setScanning(true);
    } else {
      setScanning(false);
    }
  };

  if (!isScanning) {
    return React.createElement(Button, {
      className: "QR_Scanner__Button button cart navigation",
      testingContext: "start_QR_scan",
      onClick: function onClick() {
        return handleReader(true);
      }
    }, "Start Scanning");
  }

  return React.createElement("div", {
    className: "QR_Scanner"
  }, React.createElement("div", {
    className: "QR_Scanner_container"
  }, React.createElement("video", {
    className: "QR_Scanner_output",
    muted: true,
    height: "80%",
    id: "video",
    width: "100%"
  }), React.createElement(ReaderComponent, {
    history: history,
    isScanning: isScanning,
    handleReader: handleReader,
    reader: reader
  })));
};

export default QRScanner;